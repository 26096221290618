import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import campaignsPlanetLogo from "../../assets/logos/campaigns-planet-logo.svg";
import { READABLE_SITE_NAME } from '../../config/constants';
import { AuthContext } from "../../AuthProvider";

const LandingHeader = () => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState("#home");

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleNavClick = (link) => {
        setActiveLink(link);
        setMenuOpen(false);
    };

    const redirectToAssitant = () => {
        navigate("/mkt/projects");
    };

    const navLinks = [
        { href: "#home", label: "Home" },
        { href: "#features", label: "Features" },
    ];

    return (
        <header className="sticky top-0 z-50 flex items-center justify-between px-4 py-2 bg-white shadow-md h-[80px]">
            <div className="flex items-center space-x-2">
                <img src={campaignsPlanetLogo} alt="Campaigns Planet Logo" className="h-8" />
                <span className="text-lg font-bold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-orange-500 md:block">
                    {READABLE_SITE_NAME.toUpperCase()}
                </span>
            </div>

            <nav className="hidden md:flex space-x-6">
                {navLinks.map(({ href, label }) => (
                    <a
                        key={href}
                        href={href}
                        onClick={() => handleNavClick(href)}
                        className={`text-[16px] hover:text-purple-500 font-poppins ${activeLink === href ? 'font-bold text-[#121212]' : 'font-medium text-[#414141]'}`}
                    >
                        {label}
                    </a>
                ))}
            </nav>

            <button
                className="hidden md:inline-block transition group flex h-10 w-[150px] items-center justify-center rounded-full bg-gradient-to-r from-[#ED499B] via-[#F765A3] to-[#F7AC32] p-[1.5px] text-white duration-300 hover:bg-gradient-to-l hover:shadow-sm hover:shadow-sm-[#F7AC32]"
                onClick={redirectToAssitant}
            >
                <div
                    className="flex h-full w-full items-center justify-center rounded-full bg-[#ffffff] text-black transition duration-300 ease-in-out group-hover:bg-gradient-to-br group-hover:from-[#ED499B] group-hover:via-[#F765A3] group-hover:to-[#F7AC32] group-hover:text-white group-hover:transition group-hover:duration-300 group-hover:ease-in-out"
                >
                    {auth.user ? "Manage Projects" : "Login"}
                </div>
            </button>

            <div className="md:hidden">
                <button onClick={toggleMenu} className="text-2xl text-purple-500">
                    {menuOpen ? <FaTimes /> : <FaBars />}
                </button>
            </div>

            {menuOpen && (
                <div className="absolute top-14 left-0 w-full bg-white shadow-md flex flex-col items-center space-y-4 py-4 md:hidden">
                    {navLinks.map(({ href, label }) => (
                        <a
                            key={href}
                            href={href}
                            onClick={() => handleNavClick(href)}
                            className={`text-[16px] hover:text-purple-500 font-poppins ${activeLink === href ? 'font-bold text-[#121212]' : 'font-medium text-[#414141]'}`}
                        >
                            {label}
                        </a>
                    ))}
                    <button
                        className="transition group flex h-10 w-[150px] items-center justify-center rounded-full bg-gradient-to-r from-[#ED499B] via-[#F765A3] to-[#F7AC32] p-[1.5px] text-white duration-300 hover:bg-gradient-to-l hover:shadow-sm hover:shadow-sm-[#F7AC32]"
                        onClick={redirectToAssitant}
                    >
                        <div
                            className="flex h-full w-full items-center justify-center rounded-full bg-[#ffffff] text-black transition duration-300 ease-in-out group-hover:bg-gradient-to-br group-hover:from-[#ED499B] group-hover:via-[#F765A3] group-hover:to-[#F7AC32] group-hover:text-white group-hover:transition group-hover:duration-300 group-hover:ease-in-out"
                        >
                            {auth.user ? "Manage Campaigns" : "Login"}
                        </div>
                    </button>
                </div>
            )}
        </header>
    );
};

export default LandingHeader;