import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDateWithOrdinal } from "../../utils/common";
import { truncateContent } from "../../utils/common";
import DataSourceType from "../../data/enums/data_source_type_enum";
import { ReactComponent as ClipboardIcon } from "../../assets/clipboard.svg";
import { copyToClipboardHandler } from "../../utils/common";

function KnowledgeBaseCard({
  knowledgeBaseData,
}) {
  const navigate = useNavigate();
  const [kbIdCopied, setKbIdCopied] = useState(null);

  const filters = knowledgeBaseData?.knowledge_base_data_filters || [];
  const kbNameTruncated = truncateContent(knowledgeBaseData.knowledge_base_name, 20).truncatedText;

  function formatFilterNameAndSource(filter, filterNameMaxLength) {
    let filterName = filter.name || "No filter name";
    if (filterNameMaxLength && filterNameMaxLength > 0) {
      filterName = truncateContent(filterName, filterNameMaxLength).truncatedText;
    }
    const filterSource = DataSourceType.getReadableFromString(filter.source) || "No filter source";
    return `${filterName} - ${filterSource}`;
  }

  const maxDFDisplayed = 3;
  const displayedFilters = filters.slice(0, maxDFDisplayed);
  const hiddenFilters = filters.length > maxDFDisplayed ? filters.slice(maxDFDisplayed) : [];
  const hiddenFiltersTitle = hiddenFilters.map((filter) => formatFilterNameAndSource(filter)).join(", ");

  const copyKBIdHandler = (e) => {
    e.stopPropagation();
    copyToClipboardHandler(knowledgeBaseData.id, setKbIdCopied);
  };

  return (
    <div
      key={knowledgeBaseData.id}
      className="flex relative flex-col justify-between items-center bg-[#F1F0F0] rounded-md shadow-lg w-full max-w-[284px] min-h-[220px] p-4 cursor-pointer"
      onClick={() => {
        navigate(`/mkt/knowledgebase/edit`, {
          state: { kb: knowledgeBaseData },
        });
      }}
    >
      <div className="absolute top-3 left-3 bg-[#1565C0] text-white font-bold px-3 py-1 rounded-md text-xs md:text-sm">
        {knowledgeBaseData.status}
      </div>

      <div className="absolute top-3 right-3 text-xs md:text-xs text-[#6B6B6B]">
        Created on:<br/>
        <span className="font-bold">
          {formatDateWithOrdinal(knowledgeBaseData.created_at) ?? "-"}
        </span>
      </div>

      <div
        title={knowledgeBaseData.knowledge_base_name}
        className="w-full text-lg md:text-xl text-center font-bold text-[#6B6B6B] px-2 break-words overflow-hidden text-ellipsis mt-9 mb-1 line-clamp-1"
      >
        {kbNameTruncated}
      </div>

      <div className="w-full justify-items-center">
        {filters.length > 0 ? (
          <ul
            className="list-disc list-inside text-xs md:text-sm text-[#6B6B6B]"
          >
            {displayedFilters.map((filter) => (
              <li key={filter.filter_id} className="mb-1" title={formatFilterNameAndSource(filter)}>
                {formatFilterNameAndSource(filter, 10)}
              </li>
            ))}
            {hiddenFilters.length > 0 && (
              <li title={hiddenFilters.length > 0 ? `More filters: ${hiddenFiltersTitle}` : ""}>...and more</li>
            )}
          </ul>
        ) : (
          <div className="text-xs md:text-sm text-[#6B6B6B]">No filters available</div>
        )}
      </div>

      <div className="flex-grow"></div>

      <div className="absolute bottom-3 left-3 flex items-center">
        <button
          onClick={copyKBIdHandler}
          className="text-[#1565C0] text-sm font-bold hover:underline"
          title="Copy knowledge base ID"
        >
          <ClipboardIcon className="h-5 w-5" />
        </button>
        
        <span className="text-xs md:text-sm text-[#6B6B6B] font-bold mr-2 pl-2">
          Copy knowledge base ID
        </span>
      </div>

      {kbIdCopied && (
        <div className="absolute bottom-3 right-3 text-xs text-green-600">
          Copied!
        </div>
      )}
    </div>
  );
};

export default KnowledgeBaseCard;
