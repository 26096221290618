import React from "react";
import delete_data_icon from "../../assets/delete_data_filter.svg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const ExpandableListItem = ({
    index,
    title,
    isExpanded = false,
    handleToggleExpand,
    handleDelete
}) => {
    return (
        <div
            className="flex justify-between items-center p-4 cursor-pointer"
            onClick={() => handleToggleExpand(index)}
        >
            <h1 className="text-lg gap-5 font-semibold text-gray-800">
                {title}
            </h1>
            {handleDelete && (
                <div className="flex ml-5 gap-5 w-full max-md:max-w-full">
                    <img 
                        src={delete_data_icon} 
                        alt="Delete item" 
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(index);
                        }}
                    />
                </div>
            )}
            <div className="text-blue-500">
                {isExpanded ? (
                    <FaChevronUp size={20} />
                ) : (
                    <FaChevronDown size={20} />
                )}
            </div>
        </div>
    );
};

export default ExpandableListItem;
