import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ArxivDateRangePicker = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    readOnly = false
}) => {
    return (
        <div className="flex gap-4">
            <div className="flex-1 shrink self-stretch px-5 py-4 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[60px] text-neutral-900">
                <label className="block text-base tracking-wide leading-none text-neutral-900">
                    Start Date:
                </label>
                <DatePicker
                    selected={startDate}
                    readOnly={readOnly}
                    onChange={(date) => setStartDate && setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select start date"
                    className="w-full bg-transparent border-none text-neutral-900 text-base tracking-wide leading-none outline-none py-2"
                />
            </div>
            <div className="flex-1 shrink self-stretch px-5 py-4 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[60px] text-neutral-900">
                <label className="block text-base tracking-wide leading-none text-neutral-900">
                    End Date:
                </label>
                <DatePicker
                    selected={endDate}
                    readOnly={readOnly}
                    onChange={(date) => setEndDate && setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select end date"
                    className="w-full bg-transparent border-none text-neutral-900 text-base tracking-wide leading-none outline-none py-2"
                />
            </div>
        </div>
    );
};

export default ArxivDateRangePicker;
