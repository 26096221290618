import Button from "../common/Button";
import emptyBox from "../../assets/icons/empty_box_icon.svg";

const EmptyStateScreen = ({setOpenModal}) => {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] p-8 mt-10">
        <img src={emptyBox} alt="Empty box" />
        <div className="text-center mb-10">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">
            No posts yet
          </h3>
          <p className="text-gray-500 max-w-sm">
            Get started by creating your first post. Your ideas and stories are waiting to be shared.
          </p>
        </div>
        <Button
          onClick={() => setOpenModal(true)} 
        >
          + Create post
        </Button>
      </div>
    );
  };

export default EmptyStateScreen;
