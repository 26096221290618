import React from 'react'
import { Link} from "react-router-dom";

import { formatDateWithOrdinal } from "../../../utils/common";
import { ReactComponent as ArrowBack } from "../../../assets/arrow_back.svg";

export default function CampaignHeader({ campaign }) {
    return (
        <div className="flex flex-row p-4 justify-between border-b-very-light-blue border-b">
            <div className="flex flex-row items-center">
                <Link to={-1}> <ArrowBack/> </Link>
                <h1 className="pl-6 text-xl font-semibold">
                    {campaign.title}
                </h1>
                <div className="w-4 h-[0px] rotate-90 border border-gray-100"></div>
                <div className="max-md:hidden text-main-gray text-sm font-medium leading-snug tracking-tight">
                    {formatDateWithOrdinal(campaign.created_at)}
                </div>
            </div>
        </div>
    )
}
