class StatusType {
  static DRAFT = "DRAFT"
  static PROCESSING = "PROCESSING"
  static IN_REVIEW = "IN_REVIEW"
  static PUBLISHED = "PUBLISHED"
  static FAILED = "FAILED"
  static DELETED = "DELETED"
  static ERROR = "ERROR"
  static SCHEDULED = "SCHEDULED"

  static toReadable(type){
    switch(type){
      case this.DRAFT:
        return "Draft";
      case this.PUBLISHED:
        return "Published";
      case this.PROCESSING:
        return "Processing";
      case this.IN_REVIEW:
        return "In Review";
      case this.SCHEDULED:
        return "Scheduled";
      case this.FAILED:
        return "Failed";
    }
  }
}

export default StatusType;
