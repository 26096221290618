import React from "react";
import hourglassIcon from "../../assets/icons/hourglass_icon.svg";
import rocketLaunchIcon from "../../assets/icons/rocket_launch_icon.svg";
import lightbulbIcon from "../../assets/icons/lightbulb_icon.svg";
import calendarIcon from "../../assets/icons/calendar_icon.svg";

const benefitsData = [
    {
        icon: <img src={hourglassIcon} alt="Save Time icon" className="w-92 h-92" />,
        title: "Save Time",
        description: "Create polished, ready-to-post content fast with Campaigns Planet’s AI-driven tool.",
    },
    {
        icon: <img src={rocketLaunchIcon} alt="Boost Engagement icon" className="w-92 h-92" />,
        title: "Boost Engagement",
        description: "Data-driven designs and optimized messaging ensure social media posts capture attention.",
    },
    {
        icon: <img src={lightbulbIcon} alt="Enhance Creative Freedom icon" className="w-92 h-92" />,
        title: "Enhance Creative Freedom",
        description: "Tailor each post to your brand with full control over design elements.",
    },
    {
        icon: <img src={calendarIcon} alt="Optimize Posting Schedule icon" className="w-92 h-92" />,
        title: "Optimize Posting Schedule",
        description: "Optimize your reach by scheduling posts when audience engagement is highest.",
    },
];

const BenefitsSection = () => {
    return (
        <section className="px-4 py-16 md:py-24 bg-white">
            <h2 className="text-3xl md:text-4xl font-extrabold text-center font-nunito-sans mb-4">
                Experience the Benefits of <br /> Campaigns Planet for Your Brand
            </h2>
            <p className="text-center text-[#000000] font-poppins font-medium text-[14px] mb-12">
                Our advanced AI platform streamlines content creation, so you can focus on strategic growth and meaningful brand engagement.
                <br />
                Discover how Campaigns Planet enhances your campaign efforts:
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {benefitsData.map((benefit, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center text-center p-6 rounded-lg"
                    >
                        <div className="w-92 h-92 flex items-center justify-center rounded-full mb-4">
                            {benefit.icon}
                        </div>
                        <h3 className="text-lg font-bold font-poppins mb-2">{benefit.title}</h3>
                        <p className="text-sm font-poppins text-gray-600">{benefit.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default BenefitsSection;