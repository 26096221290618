import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ArxivDateRangePicker from "./ArxivDateRangePicker";
import Dropdown from "../common/forms/Dropdown";
import DataSourceType from "../../data/enums/data_source_type_enum";
import FileUploader from "./FileUploader";
import { validateInput } from "../../utils/validations";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";

const DataFilterForm = ({
    item,
    handleSelect,
    readOnly = false,
    onChangeName,
    setStartDate,
    setEndDate,
    setKeywords,
    setAuthors,
    selectedFiles,
    handleFilesChange,
    currentDataFilters = [],
    fromEdit = false,
    validateInputs = false,
    setValidateInputs,
}) => {

    useEffect(() => {
        if (validateInputs) {
            validateAllInputs();
            setValidateInputs(false);
        }
      }, [validateInputs])

    const [errors, setErrors] = useState({
        name: '',
        source: '',
        keywords: '',
        authors: '',
    });

    const handleValidateInputSource = (source) => {
        validateInput(source, 'source', setErrors, 'Select a source for the data filter.');
        if (source.trim()) {
            handleSelect(DataSourceType.toBackendType(source));
        }
    };

    let dataSourceOptions = [DataSourceType.X, DataSourceType.ARXIV, DataSourceType.CUSTOM_FILES];
    const existCustomDataFilter = currentDataFilters.some(df => DataSourceType.CUSTOM_FILES === DataSourceType.getTypeFromString(df.source));

    const handleInputChange = (setter, field, errorMessage) => (e) => {
        const value = e.target.value;
        if(setter) setter(value);
        validateInput(value, field, setErrors, errorMessage);
    };

    const handleAuthorsOrKeywordsInputChange = (setter, field, errorMessage) => (e) => {
        const value = e.target.value;
        const otherFieldName = field === 'keywords' ? 'authors' : 'keywords';
        const otherFieldValue = field === 'keywords' ? item.authors : item.keywords;
        if(setter) setter(value);
        const valueToValidate = `${value}${otherFieldValue}`;
        const inputError = validateInput(valueToValidate, field, setErrors, errorMessage);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [otherFieldName]: inputError
                ? inputError
                : "",
        }));
    };

    const validateName = () => {
        if(readOnly) return;
        validateInput(item.name, 'name', setErrors, 'Data filter name is required')
    };

    const validateKeywordsAndAuthors = () => {
        if(readOnly) return;
        const valueToValidate = `${item.keywords}${item.authors}`;
        validateInput(valueToValidate, 'keywords', setErrors, 'At least a keyword or author is required.')
        validateInput(valueToValidate, 'authors', setErrors, 'At least a keyword or author is required.')
    };

    const validateSource = () => {
        if(readOnly) return;
        const isValidDS = DataSourceType.getTypeFromString(item.source) !== DataSourceType.NO_SELECTED;
        setErrors((prevErrors) => ({
            ...prevErrors,
            ["source"]: isValidDS
                ? ""
                : "Data filter source is required",
        }));
    };

    const validateAllInputs = () => {
        validateName();
        validateKeywordsAndAuthors();
        validateSource();
    };

    return (
        <div className="p-4 bg-white flex flex-col justify-center w-full text-base tracking-wide leading-none text-neutral-900 space-y-6">
            <div className="flex flex-col space-y-4">
                <div className="flex-1 shrink self-stretch px-5 py-4 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[60px]">
                    <input
                        type="text"
                        placeholder="Data Filter Name *"
                        value={item.name}
                        readOnly={readOnly}
                        onChange={!readOnly && handleInputChange(
                            onChangeName,
                            "name",
                            "Data filter name is required"
                        )}
                        className="w-full bg-transparent border-none text-neutral-900 text-base tracking-wide leading-none outline-none py-2"
                        onBlur={validateName}
                    />
                </div>
                {!readOnly && <InputErrorDisplayer message={errors.name} className="mt-1"/>}

                <Dropdown
                    selectedOption={item.source}
                    options={dataSourceOptions}
                    onSelect={handleValidateInputSource}
                    labelExtractor={(option) => DataSourceType.toReadable(DataSourceType.getTypeFromString(option))}
                    disabledExtractor={(option) => {
                        const isCustomFilesOption = DataSourceType.getTypeFromString(option) === DataSourceType.CUSTOM_FILES;
                        return isCustomFilesOption && (existCustomDataFilter || fromEdit);
                    }}
                    readOnly={readOnly}
                />
                {!readOnly && <InputErrorDisplayer message={errors.source} className="mt-1"/>}

                {DataSourceType.getTypeFromString(item.source) === DataSourceType.ARXIV && (
                    <ArxivDateRangePicker
                        startDate={item.start_date}
                        endDate={item.end_date}
                        readOnly={readOnly}
                        setStartDate={readOnly ? null : setStartDate}
                        setEndDate={readOnly ? null : setEndDate}
                    />
                )}

                {DataSourceType.getTypeFromString(item.source) === DataSourceType.CUSTOM_FILES && (
                    <FileUploader
                        maxFiles={10}
                        maxFileSizeMB={10}
                        initialFiles={selectedFiles}
                        onFilesChange={handleFilesChange}
                        readonly={readOnly}
                    />
                )}

                {DataSourceType.getTypeFromString(item.source) !== DataSourceType.CUSTOM_FILES && (<>
                    <div className="flex flex-col w-full">
                        <div className="flex-1 shrink self-stretch px-5 py-4 w-full text-base tracking-wide leading-none bg-white rounded-lg border border-solid border-slate-300 min-h-[60px] text-neutral-900">
                            <input
                                type="text"
                                placeholder="Filter by Keywords"
                                value={item.keywords}
                                readOnly={readOnly}
                                onChange={!readOnly && handleAuthorsOrKeywordsInputChange(
                                    setKeywords,
                                    "keywords",
                                    "At least a keyword or author is required."
                                )}
                                className="w-full bg-transparent border-none text-neutral-900 text-base tracking-wide leading-none outline-none py-2"
                                onBlur={validateKeywordsAndAuthors}
                            />

                        </div>
                        {!readOnly && <InputErrorDisplayer message={errors.keywords} className="mt-1"/>}
                        <div className="mt-3 text-sm tracking-wide leading-none text-gray-700">
                            To add multiple keywords, separate them with commas
                        </div>
                    </div>
                    <div className="flex flex-col w-full">
                        <div className="flex-1 shrink self-stretch px-5 py-4 w-full text-base tracking-wide leading-none bg-white rounded-lg border border-solid border-slate-300 min-h-[60px] text-neutral-900">
                            <input
                                type="text"
                                placeholder="Filter by Authors"
                                value={item.authors}
                                readOnly={readOnly}
                                onChange={!readOnly && handleAuthorsOrKeywordsInputChange(
                                    setAuthors,
                                    "authors",
                                    "At least a keyword or author is required."
                                )}
                                className="w-full bg-transparent border-none text-neutral-900 text-base tracking-wide leading-none outline-none py-2"
                                onBlur={validateKeywordsAndAuthors}
                            />
                        </div>
                        {!readOnly && <InputErrorDisplayer message={errors.authors} className="mt-1"/>}
                        <div className="mt-3 text-sm tracking-wide leading-none text-gray-700">
                            To add multiple authors, separate them with commas
                        </div>
                    </div>
                </>)}
            </div>
        </div>
    );
};

export default DataFilterForm;
