import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import StatusType from "../../../data/enums/statusType";
import { extractPkSk } from "../../../utils/common";
import EditableSpan from '../EditableSpan';

export default function CampaignPostsDisplayer({
    posts,
    selectedPost,
    setSelectedPostSkuuid,
    deletePostHandler,
}) {
    const [isInput, setIsInput] = useState(false);

    return (
        <ul className="flex flex-col h-[calc(100vh-250px)] max-lg:h-[calc(100vh-175px)] overflow-y-auto">
            {posts.map((post) => (
                <a
                    key={post.id}
                    onClick={() => {
                        const { skuuid: postSkuuid } = extractPkSk(post.id);
                        setSelectedPostSkuuid(postSkuuid);
                    }}
                >
				<li
					className={`flex justify-between px-3 py-4 rounded-md mt-4 relative ${
						selectedPost?.id === post.id
							? (isInput ? "bg-[#4AB3F4] text-white" : "bg-main-blue text-white")
							: (post.state === StatusType.PROCESSING && "bg-none") || "bg-light-blue"
                    }`}
				>
                        <EditableSpan
                            initialContent={post.title}
                            setIsInput={setIsInput}
                            postId={post.id}
                        />
                        {post.state === StatusType.PROCESSING ||
                            post.state === StatusType.FAILED ? (
                            <div
                                className={`h-5 w-[60px] ${post.state === StatusType.PROCESSING
                                    ? "bg-[#1DA1F2]"
                                    : "bg-[#EF4444]"
                                    }  text-white text-center`}
                            >
                                <span className="text-[10px]">
                                    {StatusType.toReadable(post.state)}
                                </span>
                            </div>
                        ) : (
                            <div
                                className="cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deletePostHandler(post);
                                }}
                            >
                                <FaTrashAlt
                                    className={` h-4 w-3 ${selectedPost?.id !== post.id && "text-[#EF4444]"
                                        } `}
                                />
                            </div>
                        )}
                    </li>
                </a>
            ))}
        </ul>
    );
}
