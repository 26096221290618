import React from 'react';

const CampaignSearch = ({
    searchInput,
    setSearchInput
}) => {
    return (
        <div className="relative">
            <input
                type="text"
                placeholder="Search"
                className="p-2 border border-gray-300 rounded-md mr-4"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 absolute right-5 top-5 -translate-y-1/2 text-gray-500 cursor-pointer"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <circle cx="11" cy="11" r="8" />
                <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
        </div>
    );
}

export default CampaignSearch;
