import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import Button from "./common/Button";
import CreateCampaignModal from "./Campaigns/CreateCampaignModal";
import CampaignTypeFilter from "./Campaigns/CampaignTypeFilter";
import CampaignSearch from "./Campaigns/CampaignSearch";
import * as NotificationService from "../utils/notificationService";
import CampaignsTable from "./Campaigns/CampaignsTable";
import { fetchProjectCampaigns, deleteCampaign } from "../services/index";

function Campaigns({ className }) {
  let { projectskuuid } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterType, setFilterType] = useState("All");

  const campaignsQuery = useQuery({
    queryKey: [`project-${projectskuuid}-campaigns`],
    queryFn: () => fetchProjectCampaigns(projectskuuid),
    staleTime: 10 * 1000,
  });

  const deleteCampaignHandler = async (campaignid) => {
    try {
      await deleteCampaign(campaignid);
      NotificationService.notifySuccess("Campaign deleted successfully");
      campaignsQuery.refetch();
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to delete campaign: ${errorMessage}`
      );
    }
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
    setSearchInput("");
  };

  const filteredCampaigns = campaignsQuery?.data
    .filter((campaign) => {
      const searchTerm = searchInput.toLowerCase();
      return campaign.title.toLowerCase().includes(searchTerm);
    })
    .filter((campaign) => {
      if (filterType === "All") return true;
      return (
        campaign.type === filterType ||
        (filterType === "Single" && campaign.type === "SINGLE") ||
        (filterType === "Multiple" && campaign.type === "MULTI")
      );
    });

  return (
    <div
      className={
        className +
        " flex flex-col rounded-md shadow shadow-yet-yet-another-gray"
      }
    >
      <div className="flex flex-row p-4 justify-between">
        <h1 className="text-2xl font-bold">Campaigns</h1>
        <CampaignSearch
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <CampaignTypeFilter
          filterType={filterType}
          handleFilterChange={handleFilterChange}
        />
        <div>
          <Button onClick={() => setOpenModal(true)}>Create</Button>
        </div>
      </div>
      <div className="h-[calc(100vh-150px)] overflow-y-auto">
        <CampaignsTable
          campaigns={filteredCampaigns}
          deleteCampaignHandler={deleteCampaignHandler}
        />
      </div>
      <CreateCampaignModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        refetch={campaignsQuery.refetch}
      />
    </div>
  );
}

export default Campaigns;
