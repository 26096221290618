import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ADD_BLUE_ICON_URL } from "../../config/ExternalResources";
import * as NotificationService from "../../utils/notificationService";
import { fetchKnowledgeBases } from "../../services";
import KnowledgeBaseCard from "./KnowledgeBaseCard";

function KnowledgeBase({ className }) {
  const location = useLocation();
  const navigate = useNavigate();
  let newKbCreated = location.state?.newKbCreated;
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCreateNewClick = () => {
    navigate(`/mkt/createknowledgeBase`);
  };

  const handleNewKnowledgeBase = (knowledgeBasesResponse) => {
    if (newKbCreated) {
      const exists = knowledgeBasesResponse.some((kb) => kb.id === newKbCreated.id);
      if (!exists) {
        knowledgeBasesResponse.push(newKbCreated);
      }
      newKbCreated = null;
    }
    return knowledgeBasesResponse;
  };

  const fetchKnowledgeBasesHandler = async () => {
    setIsLoading(true);
    try {
      let knowledgeBasesResponse = await fetchKnowledgeBases();
      knowledgeBasesResponse = handleNewKnowledgeBase(knowledgeBasesResponse);
      setKnowledgeBases(knowledgeBasesResponse);
    } catch (error) {
      console.error("Failed to fetch knowledge bases: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to fetch knowledge bases: ${errorMessage}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchKnowledgeBasesHandler();
  }, [navigate]);

  return (
    <div
      className={`${className} flex flex-col rounded-md shadow shadow-yet-yet-another-gray`}
    >
      <div className="flex flex-col rounded-md shadow shadow-yet-yet-another-gray">
        <div className="flex flex-row p-4 justify-between">
          <h1 className="text-2xl font-bold">Knowledge Bases</h1>
          <div className="flex overflow-hidden flex-col flex-1 w-full text-xl text-center text-sky-500 rounded-none max-md:max-w-full"></div>
        </div>
        <div className="flex flex-row rounded-md shadow p-5 shadow-yet-yet-another-gray">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <div onClick={handleCreateNewClick}>
              <div className="flex flex-col justify-center max-w-full bg-white rounded min-h-[220px] rounded-md shadow shadow-yet-yet-another-black shadow-lg w-[284px] cursor-pointer">
                <img
                  loading="lazy"
                  src={ADD_BLUE_ICON_URL}
                  className="object-contain self-center w-12 aspect-square"
                  alt="Create New Knowledge Base"
                />
                <div className="mt-5 w-full text-center font-bold text-[#1DA1F2]">
                  Create New <br />
                  Knowledge Base
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="flex flex-col items-center justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full">
                <div className="spinner"></div>
              </div>
            ) : (
              knowledgeBases?.map((knowledgeBaseData) => (
                <KnowledgeBaseCard
                  key={knowledgeBaseData.id}
                  knowledgeBaseData={knowledgeBaseData}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default KnowledgeBase;
