import React, { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import * as NotificationService from "../../utils/notificationService";
import { validateInput } from "../../utils/validations";
import { createProject } from "../../services";
import { CLOSE_ICON_URL } from "../../config/ExternalResources";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";

const ProjectModal = ({ closeModal }) => {
  const queryClient = useQueryClient();
  const [projectTitle, setProjectTitle] = useState("");
  const initialErrors = { title: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

  useEffect(() => {
    setIsCreateButtonDisabled(!projectTitle.trim());
  }, [projectTitle]);

  const handleSave = async () => {
    try {
      const createdProject = await createProject({ title: projectTitle });
      NotificationService.notifySuccess("Project created successfully");
      queryClient.setQueryData(["myProjects"], (oldData) => {
        return oldData
          ? [...oldData, createdProject]
          : [createdProject];
      });
      closeModal();
    } catch (error) {
      console.error("Failed to create Project: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to create Project: ${errorMessage}`
      );
    }
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleCancel = () => {
    closeModal();
    setProjectTitle("");
    setErrors(initialErrors);
  };

  const validateProjectTitle = () => {
    validateInput(
      projectTitle,
      "title",
      setErrors,
      "Project title is required"
    );
  };

  const validateAllInputs = () => {
    validateProjectTitle();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white rounded-2xl shadow-lg  p-6">
        <div className="text-center text-2xl font-bold mb-4">New Project</div>
        <img
          loading="lazy"
          onClick={handleCancel}
          src={CLOSE_ICON_URL}
          className="object-contain w-6 h-6 absolute top-3 right-3 cursor-pointer"
          alt="Close modal"
        />
        <div className="space-y-6">
          <div>
            <label className="block text-black">Project title *</label>
            <textarea
              className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
              placeholder="Project title"
              value={projectTitle}
              onBlur={validateProjectTitle}
              onChange={handleInputChange(
                setProjectTitle,
                "title",
                "Project title is required"
              )}
            />
            <InputErrorDisplayer message={errors.title} />
          </div>
        </div>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel="Create"
            saveDisabled={isCreateButtonDisabled}
            onCancel={handleCancel}
            onSave={handleSave}
            onSaveDisabled={validateAllInputs}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;
