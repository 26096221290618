import React from "react";
import DataSourceType from "../../data/enums/data_source_type_enum";
import arxivLogo from "../../assets/logos/arxiv-logo.svg";
import xLogo from "../../assets/x.svg";
import { groupReferencesBySource } from "../../utils/common";
import { isEmpty } from "../../utils/validations";

export const RenderReferences = (references, logo, dataSourceType) => (
  <div className="flex flex-col mt-2">
    <div className="flex items-center">
      <img
        src={logo}
        alt={`${DataSourceType.toReadable(dataSourceType)} logo`}
        className={
          DataSourceType.X === dataSourceType ? "w-5 h-5 mr-2" : "w-10 h-5 mr-2"
        }
        title={DataSourceType.toReadable(dataSourceType)}
      />
    </div>
    <div className="flex flex-wrap items-start mt-2">
      {references.map((ref, index) => {
        const referenceTitle =
          dataSourceType === DataSourceType.ARXIV ? ref.title : ref.author;
        const truncatedTitle =
          referenceTitle.length > 35
            ? `${referenceTitle.substring(0, 35)}...`
            : referenceTitle;

        return (
          <React.Fragment key={index}>
            <a
              href={ref.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
              title={referenceTitle}
            >
              {truncatedTitle}
            </a>
            {index < references.length - 1 && <span className="mr-2">; </span>}
          </React.Fragment>
        );
      })}
    </div>
  </div>
);

const SourcesList = ({ reference }) => {
  if (!reference || reference.length === 0) return null;

  const groupedReferences = groupReferencesBySource(reference);

  return (
    <div className="flex flex-col mt-3 rounded-xl bg-gray-50 p-4">
      {!isEmpty(reference[0]) && <span className="pb-2">Sources:</span>}
      {groupedReferences[DataSourceType.X] &&
        RenderReferences(
          groupedReferences[DataSourceType.X],
          xLogo,
          DataSourceType.X
        )}

      {groupedReferences[DataSourceType.ARXIV] &&
        RenderReferences(
          groupedReferences[DataSourceType.ARXIV],
          arxivLogo,
          DataSourceType.ARXIV
        )}
    </div>
  );
};

export default SourcesList;
