import React, {useEffect, Suspense } from 'react'
import { Amplify } from "aws-amplify"
import { Routes, Route } from "react-router-dom"
import { CookieStorage } from 'aws-amplify/utils'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import CampaignPage from './components/Campaigns/CampaignPage'
import ProjectsPage from './components/Projects/ProjectsPage'

import { AuthProvider, RequireAuth, AuthCallback } from './AuthProvider'
import LandingPageV2 from './components/Landing/LandingPageV2'
import LoginPage from './LoginPage'
import Console from './Console'
import Settings from './components/Settings'
import LinkedInCallback from './components/LinkedInCallback'
import Loading from './components/common/Loading'
import Campaigns from './components/Campaigns'
import KnowledgeBase from './components/knowledge_base/KnowledgeBase'
import XCallback from './components/XCallback'

import EditKnowledgeBase from './components/knowledge_base/EditKnowledgeBase'
import PromptTemplate from './components/prompt_template/PromptTemplate'
import CreateKnowledgeBase from './components/knowledge_base/CreateKnowledgeBase'
import * as NotificationService from "./utils/notificationService";
import PostEditStep from './components/designStudio/PostEditStep'

import {
  COGNITO_DOMAIN, COGNITO_USER_POOL_APP_CLIENT_ID, COGNITO_USER_POOL_ID
} from './config/constants'

import './App.css'
import CampaignProvider from './context/campaigns/CampaignProvider'


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolClientId: COGNITO_USER_POOL_APP_CLIENT_ID,

      loginWith: {
        oauth: {
          domain: COGNITO_DOMAIN,
          // TODO: revisit aws.cognito.signin.user.admin scope
          scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
          redirectSignIn: ["http://localhost:8080/auth/callback", "https://campaignsplanet.com/auth/callback", "https://stg.campaignsplanet.com/auth/callback"],
          redirectSignOut: ["http://localhost:8080/", "https://campaignsplanet.com/","https://stg.campaignsplanet.com/"],
          responseType: "token",
        },
      },

      cookieStorage: {
        domain: "localhost",
        path: "/",
        expires: 1,
        sameSite: "strict",
        secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== "localhost",
      },
    },
  }
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage())

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
})

function App() {
  useEffect(() => {
    var noHashURL = window.location.href.replace(/#.*$/, '');
    window.history.replaceState('', document.title, noHashURL)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loading/>}>
        {NotificationService.renderToastContainer()}
        <AuthProvider>
          <CampaignProvider>
            <Routes>
              <Route path="/" element={<LandingPageV2 />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/auth/callback" element={<AuthCallback />} />
              <Route path="/mkt" element={<RequireAuth><Console/></RequireAuth>}>
                <Route path="apps/linkedin/auth" element={<LinkedInCallback/>}/>
                <Route path="apps/x/auth" element={<XCallback/>}/>
                <Route path="settings" element={<Settings/>}/>
                <Route path="knowledgeBase" element={<KnowledgeBase/>}/>
                <Route path="knowledgebase/edit" element={<EditKnowledgeBase/>}/>
                <Route path="createknowledgeBase" element={<CreateKnowledgeBase/>}/>
                <Route path="promptTemplate" element={<PromptTemplate/>}/>
                  <Route path="projects">
                    <Route index element={<ProjectsPage/>} />
                    <Route path=":projectskuuid/campaigns">
                      <Route index element={<Campaigns/>} />
                      <Route path=":campaignskuuid/assistant" element={<CampaignPage/>}/>
                      <Route path=":campaignskuuid/edit" element={<PostEditStep/>}/>
                    </Route>
                  </Route>
              </Route>
            </Routes>
          </CampaignProvider>
        </AuthProvider>
      </Suspense>
    </QueryClientProvider>
  )
}

export default App
