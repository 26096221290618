import { useEffect, useState, useRef, useContext } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { AuthContext } from "../../../AuthProvider";
import { ReactComponent as AgentAvatar } from "../../../assets/agent_avatar.svg";
import Button from "../../common/Button";
import AssistantMessageInput from "../AssistantMessageInput";
import SourcesList from "../../Campaigns/SourcesList";
import Typing from "../../Typing";
import PromptTemplatesDisplayer from "../PromptTemplates";
import {
    fetchPostMessages,
    sendMessage,
    fetchMessageByRunId,
} from "../../../services";
import AIModelType from "../../../data/enums/AI_model_type";
import * as NotificationService from "../../../utils/notificationService";
import { extractPkSk } from "../../../utils/common";

export default function PostConversation({
    post,
    systemPromptTemplates,
    projectPromptTemplates,
    messagesUpdatedCallback,
}) {
    let auth = useContext(AuthContext);
    const { pkuuid: postPkuuid, skuuid: postSkuuid } = extractPkSk(post.id);
    const elementRef = useRef();
    const queryClient = useQueryClient();

    const [localMessage, setLocalMessage] = useState([]);
    const [isWatingAssistant, setIsWatingAssistant] = useState(false);
    const [messageInput, setMessageInput] = useState("");
    const [isAssistantFailed, setIsAssistantFailed] = useState(false);


    const promptTemplates = [
        ...(projectPromptTemplates || []),
        ...(systemPromptTemplates || [])
    ];

    const messagesQuery = useQuery({
        queryKey: [`post-${postSkuuid}-messages`],
        queryFn: () => fetchPostMessages(post.id),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        setLocalMessage((_prev) => [
            {
                type: "assistant",
                message_type: "text",
                message: `Hello, tell me what’s on your mind or pick up a suggested prompt templates`,
            },
            ...messagesQuery.data,
        ]);
        messagesUpdatedCallback(messagesQuery.data)
        elementRef.current.scrollIntoView();
    }, [auth.user.given_name, messagesQuery.data]);

    const handleInputChange = (event) => {
        setMessageInput(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const handleModelMessage = aIModelMessageHandlerMap[AIModelType.getTypeFromString(post.ai_model)]
            handleModelMessage(messageInput);
        }
    };

    const handleSendClick = () => {
        if (!isWatingAssistant) {
            const event = new KeyboardEvent('keydown', {
                key: 'Enter',
                code: 'Enter',
            });
            handleKeyDown(event);
        }
    };

    const handlePromptChange = (templateId) => {
        const selectedItem = promptTemplates.find(
            (item) => item.prompt_id === templateId
        );
        if (selectedItem) {
            setMessageInput(selectedItem.prompt_template_content);
        }
    };

    const sendMessageWithOpenAI = async (_message) => {
        setIsAssistantFailed(false);
        setLocalMessage((prev) => [
            ...prev,
            {
                type: "user",
                message: _message,
            },
        ]);
        setMessageInput("");
        setIsWatingAssistant(true);

        elementRef.current.scrollIntoView();

        try {
            const payload = { 'message': _message, }
            const { run_id } = await sendMessage(postPkuuid, postSkuuid, payload)

            pollForCompletion(run_id);
            elementRef.current.scrollIntoView();

        } catch (error) {
            setIsWatingAssistant(false);
            setIsAssistantFailed(true);
            elementRef.current.scrollIntoView();
            console.error("Failed fetch assistance message: ", error);
            const errorMessage = error.message || "An unexpected error occurred";
            NotificationService.notifyError(
                `Failed fetch assistance message: ${errorMessage}`
            );
        }
    };

    const pollForCompletion = async (runId) => {
        try {
            const payload = { "run_id": runId }
            const response = await fetchMessageByRunId(postPkuuid, postSkuuid, payload);

            const data = await response;
            setLocalMessage([]);
            queryClient.setQueryData([`post-${postSkuuid}-messages`], data);
            const lastMessage = data[data.length - 1];
            if (lastMessage.message_type === "json") {
                queryClient.setQueryData([`post-${postSkuuid}`], (old) => {
                    return {
                        ...old,
                        caption: lastMessage.message.caption,
                        image_prompt: lastMessage.message.image_prompt,
                    };
                });
            }
            setIsWatingAssistant(false);
        } catch (error) {
            console.error("Failed to fetch assistance message: ", error);
            const errorMessage = error.message || "An unexpected error occurred";
            NotificationService.notifyError(
                `Failed to fetch assistance message: ${errorMessage}`
            );
        }
    };

    // TODO: Implement GROQ_AI
    const aIModelMessageHandlerMap = {
        [AIModelType.OPEN_AI]: (message) => sendMessageWithOpenAI(message),
    };

    return (
        <>
            {messagesQuery.data.length <= 0 && (
                <PromptTemplatesDisplayer
                    promptTemplates={promptTemplates}
                    handlePromptChange={handlePromptChange}
                />
            )}
            <ul
                className={`flex flex-col overflow-y-auto rounded-xl p-4 ${messagesQuery.data.length <= 0
                    ? "h-[calc(80vh-250px)] max-lg:h-[calc(80vh-175px)]"
                    : "h-[calc(100vh-250px)] max-lg:h-[calc(80vh-175px)]"
                    }`}
            >
                {localMessage.map((m, i) => (
                    <div key={i}>
                        <li
                            className={
                                m.type === "user"
                                    ? "flex flex-row mt-3"
                                    : "flex flex-row mt-3 rounded-xl bg-blue-50"
                            }
                        >
                            <span className="p-2">
                                {m.type === "user" ? (
                                    <div className="w-8 h-8 p-2.5 bg-main-blue rounded-full flex items-center justify-center">
                                        <div className="text-center text-white text-base font-normal">
                                            {auth.user.given_name[0]}
                                            {auth.user.family_name[0]}
                                        </div>
                                    </div>
                                ) : (
                                    <AgentAvatar className="bg-black rounded-full w-8 h-8 px-1 py-1 shadow-md" />
                                )}
                            </span>
                            <span className="p-2 pt-2 inline-flex items-center whitespace-pre-wrap">
                                {typeof m.message === "object"
                                    ? m.message.assistant_message
                                    : m.message}
                            </span>
                        </li>
                        {m.reference && <SourcesList reference={m.reference} />}
                    </div>
                ))}
                {isWatingAssistant && (
                    <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
                        <span className="py-4 pl-3">
                            <Typing />
                        </span>
                    </li>
                )}
                {isAssistantFailed && (
                    <li className="flex flex-row mt-3 rounded-xl bg-very-light-blue">
                        <span className="p-2">
                            <AgentAvatar className="bg-black rounded-3xl w-8 h-8 px-1 py-1 shadow-transparent" />
                        </span>
                        <span className="p-2">
                            <span className="pr-2">
                                It seems like something whent wrong.
                            </span>
                            <Button onClick={() => sendMessageWithOpenAI("retry")}>
                                Retry
                            </Button>
                        </span>
                    </li>
                )}
                <li ref={elementRef}></li>
            </ul>
            <AssistantMessageInput
                messageInput={messageInput}
                handleChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                disabled={isWatingAssistant}
                handleSendClick={handleSendClick}
            />
        </>
    )
}
