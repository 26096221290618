const Typing = () => {
  const keyframes = `
  @keyframes loadingFade {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    50% {
      opacity: 0.8;
      transform: scale(0.8);
    }
    100% {
      opacity: 0;
      transform: scale(1.0);
    }
  }
  `

  const dotStyle = {
    float: 'left',
    width: '8px',
    height: '8px',
    margin: '0 4px',
    background: '#1DA1F2',
    borderRadius: '50%',
    opacity: 0,
    animation: 'loadingFade 1s infinite',
  };

  const dot1Style = { ...dotStyle, animationDelay: '0s' };
  const dot2Style = { ...dotStyle, animationDelay: '0.2s' };
  const dot3Style = { ...dotStyle, animationDelay: '0.4s' };

  return (
    <div>
      <style>
        { keyframes }
      </style>
      <div>
        <div style={dot1Style}></div>
        <div style={dot2Style}></div>
        <div style={dot3Style}></div>
      </div>
    </div>
  )
}

export default Typing
