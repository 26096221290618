import React from "react";

const AddDataFilterButton = ({ title, onClick }) => {
    return (
        <div
            onClick={onClick}
            className="flex gap-2 justify-center items-center self-center px-4 py-5 text-lg font-semibold tracking-tight leading-none text-sky-500 bg-white rounded-3xl shadow-[0px_0px_1px_rgba(10,22,70,0.06)] max-md:mt-10 cursor-pointer"
        >
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/593c3f3b58c11c1ae6bc395912fafb08b42b2ef70cc94fdd727b61925fe340fb?apiKey=ed65200cdc83432eae597ee1cdaa24d7&&apiKey=ed65200cdc83432eae597ee1cdaa24d7"
                className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
            />
            <div className="self-stretch my-auto text-[#1DA1F2] cursor-pointer">
                {title ?? 'Add Data Filter'}
            </div>
        </div>
    );
};

export default AddDataFilterButton;
