import { fetchAuthSession } from "aws-amplify/auth"
import { Navigate, useLocation } from "react-router-dom"
import { useState, useEffect, useMemo } from "react"
import { useQueryClient } from "@tanstack/react-query";
import Loading from "./common/Loading"
import { API_SERVER } from "../config/constants"
import { callbackURL } from "../utils/common"
import * as NotificationService from "../utils/notificationService";

function useURLQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function LinkedInCallback() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  let urlQueryParams = useURLQueryParams();

  useEffect(() => {
    setIsLoading(true)
    fetchAuthSession().then((session) => {
      let metadata = urlQueryParams.get('state');
      let parsedMetadata = {};
      try {
        parsedMetadata = JSON.parse(atob(metadata));
      } catch (error) {
        console.error("Failed to decode metadata", error);
      }
      const accountName = parsedMetadata.account_name || 'Default account name';
      fetch(API_SERVER+'/apps/linkedin/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`
        },
        body: JSON.stringify({
          'code': urlQueryParams.get('code'),
          'callback_url': callbackURL('LinkedIn'),
          'meta': {
            'account_name': accountName
          },
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.message || "Network response was not ok");
        }
        NotificationService.notifySuccess("Social network connected successfully");
        queryClient.invalidateQueries(['socialAccounts']);
      })
      .catch((error) => {
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(`Failed to connect: ${errorMessage}`);
      }).finally(() => {
        setIsLoading(false)
      });
    })
  }, [urlQueryParams])

  return isLoading ? <Loading /> : <Navigate to='/mkt/settings' />;
}

export default LinkedInCallback
