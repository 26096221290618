class CampaignType {
  static MANUAL = "MANUAL"
  static AUTOMATIC = "AUTOMATIC"

  static toReadable(type){
    switch(type){
      case this.MANUAL:
        return "Manual"
      case this.AUTOMATIC:
        return "Multiple Post with CSV file"
      default:
        return null;
    }
  }

}

export default CampaignType;
