import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { processImage, updatePost } from "../../services";
import PercentageLoader from "../common/loaders/PercentageLoader";
import { createStore } from "polotno/model/store";
import { POLOTNO_KEY } from "../../config/constants";
import Workspace from "polotno/canvas/workspace";
import { extractPkSk } from "../../utils/common";

function Image(props) {
  const queryClient = useQueryClient();
  const prevPromptRef = useRef(props.post.image_prompt);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);
  const [imageError, setImageError] = useState(false);
  const workspaceRef = useRef(null);
  const [store] = useState(() =>
    createStore({ key: POLOTNO_KEY, showCredit: true })
  );
  const { pkuuid: campaignSkuuid, skuuid: postSkuuid } = extractPkSk(props.post.id);

  const processImageMutation = useMutation({
    mutationFn: async ({ prompt}) => {
      try {
        setIsLoading(true);
        setIsImageReady(false);
        setImageError(false);

        const imageObj = await processImage(
          campaignSkuuid,
          postSkuuid,
          prompt,
        );
        queryClient.setQueryData([`post-${postSkuuid}`], (old) => {
          return { ...old, image: imageObj.image };
        });

        if (props.post.edit_state) {
          const newState = {
            ...props.post.edit_state,
            pages: props.post.edit_state.pages.map((page) => {
              if (page.id === props.post.edit_state.pages[0].id) {
                return {
                  ...page,
                  children: page.children.map((children) => {
                    if (children.type === "image") {
                      children.src = imageObj.post_image;
                    }
                    return children;
                  }),
                };
              }
              return page;
            }),
          };

          await store.loadJSON(newState);

          await new Promise((resolve) => setTimeout(resolve, 100));


          const pageId = props.post.edit_state.pages[0].id;
          const updatedImageDataURL = await store.toDataURL({
            pageId,
            mimeType: "image/jpg",
          });

          const postPayload = {
            edit_state: newState,
            post_edit_image: updatedImageDataURL,
          };

          await updatePost(props.post.id, postPayload);

          queryClient.invalidateQueries([`post-${postSkuuid}`]);
        }

        setIsImageReady(true);
      } catch (error) {
        console.error("Error processing image:", error);
        setImageError(true);
      } finally {
        setIsLoading(false);
      }
    },
    onError: () => {
      setIsLoading(false);
      setImageError(true);
    },
  });

  useEffect(() => {
    if (
      !props.post.image ||
      props.post.image_prompt !== prevPromptRef.current
    ) {
      processImageMutation.mutate({ prompt: props.post.image_prompt });
    }
  }, [props.post.image_prompt]);


  return (
    <div>
      {isLoading ? (
        <PercentageLoader
          isLoading={isLoading}
          isImageReady={isImageReady}
          setIsLoading={setIsLoading}
        />
      ) : (
        <div className="relative flex flex-row justify-center">
          <img
            src={props.post.post_edit_image || props.post.image}
            alt={props.post.image_prompt}
            onLoad={() => setIsImageReady(true)}
            onError={() => {
              setImageError(true);
              setIsImageReady(false);
            }}
            style={{
              display: imageError ? "none" : "block",
            }}
          />
          {imageError && (
            <div className="text-red-500">Error loading image</div>
          )}
        </div>
      )}
      <div style={{ display: "none" }} ref={workspaceRef}>
        <Workspace store={store} />
      </div>
    </div>
  );
}

export default Image;
