import React from "react";

const RadioInputGroup = ({
  name,
  options,
  selectedValue,
  onChange,
  legend,
}) => (
  <fieldset className="flex max-w-md flex-row gap-4">
    <legend className="mb-4 font-semibold">{legend}</legend>
    <div className="flex items-center gap-2">
      {options.map((option) => (
        <div className="flex items-center gap-2" key={option.id}>
          <input
            type="radio"
            id={option.id}
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={onChange}
          />
          <label htmlFor={option.id}>{option.label}</label>
        </div>
      ))}
    </div>
  </fieldset>
);

export default RadioInputGroup;
