import {
  createContext, useContext
} from 'react'
import {
  useLocation,
  Navigate,
} from "react-router-dom"
import {
  useQuery
} from '@tanstack/react-query'
import { signInWithRedirect, fetchUserAttributes, fetchAuthSession, signOut } from "aws-amplify/auth"

let AuthContext = createContext()

const checkSession  = async () => {
  const session = await fetchAuthSession()
  if (session.tokens)
    return await fetchUserAttributes()

  return Promise.resolve(null)
}

// const checkSession = async () => {
//   const session = await fetchAuthSession();
//   if (session.tokens) {
//     const userAttributes = await fetchUserAttributes();
//     if (userAttributes.email.endsWith('@easy-donate.com')) {
//       return await fetchUserAttributes()
//     } else {
//       await signOut();
//       return null;
//     }
//   }
//   return null;
// };

function AuthProvider({ children }) {
  const signin = async () => {
    await signInWithRedirect({
      provider: "Google",
    })
  }

  const signout = async () => {
    await signOut({
      provider: "Google",
    })
  }

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: checkSession,
  })

  let value = { user, signin, signout }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

function RequireAuth({ children }) {
  let location = useLocation()
  const auth = useContext(AuthContext)

  localStorage.setItem('callback-redirect', location.pathname)
  return auth.user ?
    <>
      {children}
    </>
    :
    <Navigate to="/login" state={{ from: location }} replace />
}

function AuthCallback() {
  const locationPath = localStorage.getItem('callback-redirect')
  if (locationPath) {
    return <Navigate to={locationPath} />
  } else {
    return <Navigate to='/mkt/projects' />
  }
}

export { AuthProvider, AuthContext, RequireAuth, AuthCallback }
