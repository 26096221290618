import React, { useState } from "react";
import { Modal } from "flowbite-react";

import StatusType from "../../../data/enums/statusType";
import SocialNetworkType from "../../../data/enums/socialNetworkType";
import { ReactComponent as LinkedInIcon } from "../../../assets/linkedin_icon.svg";
import { ReactComponent as XIcon } from "../../../assets/x.svg";
import { ReactComponent as ScheduledIcon } from "../../../assets/icons/schedule_icon.svg";
import ActionButtonsGroup from "../../common/buttons/ActionButtonsGrup";
import { formatDateWithOrdinal, truncateContent } from "../../../utils/common";


export default function PublishModal({
    openModal,
    setOpenModal,
    projectSocialAccounts,
    postTargetPlatforms,
    handlePublish,
    handleSchedule,
    selectedAccountIds,
    setSelectedAccountIds,
}) {
    const [expandedSections, setExpandedSections] = useState({});

    const handleClose = () => {
        setOpenModal(false);
        setSelectedAccountIds([]);
    };

    const handleAccountSelect = (accountId) => {
        setSelectedAccountIds((prevSelected) =>
            prevSelected.includes(accountId)
                ? prevSelected.filter((item) => item !== accountId)
                : [...prevSelected, accountId]
        );
    };

    const isTargetPlatform = (accountId) =>
        postTargetPlatforms.find(
            (platform) => platform.social_account_id === accountId
        );

    const groupedAccounts = projectSocialAccounts.reduce((acc, account) => {
        const platform = account.app.split("#")[0];
        if (!acc[platform]) {
            acc[platform] = [];
        }
        acc[platform].push(account);
        return acc;
    }, {});

    const toggleSection = (platform) => {
        setExpandedSections((prev) => ({
            ...prev,
            [platform]: !prev[platform],
        }));
    };

    const socialNetworkIconMap = {
        [SocialNetworkType.LINKEDIN]: LinkedInIcon,
        [SocialNetworkType.X]: XIcon,
    };

    const targetDataMap = {
        [StatusType.PUBLISHED]: (target) => ({
            bgColor: "bg-main-blue",
            date: target.published_at,
            statusReadable: StatusType.toReadable(StatusType.PUBLISHED),
            textColor: "text-white",
        }),
        [StatusType.SCHEDULED]: (target) => ({
            bgColor: "bg-[#F3F3F4]",
            date: target.schedule_time,
            statusReadable: StatusType.toReadable(StatusType.SCHEDULED),
            textColor: "text-[#000000]",
        }),
        [StatusType.FAILED]: (target) => ({
            bgColor: "bg-red-500",
            date: target.created_at,
            statusReadable: StatusType.toReadable(StatusType.FAILED),
            textColor: "text-white",
        }),
    };


    return <Modal
        show={openModal}
        size="lg"
        onClose={handleClose}
        className="rounded-2xl"
    >
        <Modal.Header className="text-center">Select Target Platform</Modal.Header>
        <Modal.Body className="p-3 py-6">
            <div className="space-y-4">
                {Object.entries(groupedAccounts).map(([platform, accounts]) => {
                    const Icon = socialNetworkIconMap[platform] ?? LinkedInIcon;
                    const connectedCount = accounts.length;
                    return (
                        <div key={platform}>
                            <button
                                className="w-full flex justify-between text-lg font-bold"
                                onClick={() => toggleSection(platform)}
                            >
                                <div className="flex items-center space-x-3">
                                    <Icon className="h-9 w-9 text-gray-700" />
                                    <div className="flex flex-col items-start">
                                        <div className="text-[16px]">{platform}</div>
                                        <div className="text-xs text-gray-500">
                                            {connectedCount} connected accounts
                                        </div>
                                    </div>
                                </div>
                                <svg
                                    className={`w-5 h-5 transition-transform transform ${expandedSections[platform] ? "rotate-180" : "rotate-0"
                                        } text-gray-500`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </button>
                            {expandedSections[platform] && (
                                <div className="mt-2 space-y-2">
                                    {accounts.map((account) => {
                                        const targetPlatform = isTargetPlatform(account.app);
                                        const isScheduled =
                                            targetPlatform &&
                                            targetPlatform.status === StatusType.SCHEDULED;
                                        const data =
                                            targetPlatform &&
                                            targetDataMap[targetPlatform.status](targetPlatform);

                                        const isSelected = selectedAccountIds.includes(account.app);

                                        return (
                                            <div key={account.app} className="pl-9 py-2">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center me-4">
                                                        <input
                                                            id={account.app}
                                                            type="checkbox"
                                                            value={account.account_name}
                                                            onChange={() =>
                                                                handleAccountSelect(account.app)
                                                            }
                                                            checked={isSelected}
                                                            disabled={!!targetPlatform}
                                                            className="w-4 h-4 text-main-blue bg-gray-100 border-gray-300 rounded focus:ring-main-blue dark:focus:ring-main-blue dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                        />
                                                        <label
                                                            htmlFor={account.app}
                                                            className="text-[14px] font-medium ml-2"
                                                        >
                                                            <div className={`line-clamp-1 font-semibold ${targetPlatform && "text-gray-500"}`}>
                                                                {truncateContent(account.account_name, 45).truncatedText}
                                                            </div>
                                                            {targetPlatform && (
                                                                <div className="text-[12px] text-gray-500">
                                                                    {formatDateWithOrdinal(data.date) ?? "-"}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                    {targetPlatform && (
                                                        <div
                                                            className={`${data.bgColor} ${data.textColor} font-bold py-1 rounded-md text-xs md:text-sm py-[12px] flex gap-[6px] min-w-[120px] justify-center`}
                                                        >
                                                            {isScheduled && <ScheduledIcon />}
                                                            {data?.statusReadable}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            <br />
                            <hr />
                        </div>
                    );
                })}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="w-full flex-row justify-end space-x-3">
                <ActionButtonsGroup
                    saveLabel="Publish Now"
                    cancelLabel="Schedule"
                    onSave={handlePublish}
                    onCancel={handleSchedule}
                    saveDisabled={selectedAccountIds.length === 0}
                    cancelDisabled={selectedAccountIds.length === 0}
                />
            </div>
        </Modal.Footer>
    </Modal>;

}