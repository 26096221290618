import React, { useState, useEffect } from "react";
import how_it_works_step_1 from "../../assets/images/how_it_works/how_it_works_step_1.svg";
import how_it_works_step_2 from "../../assets/images/how_it_works/how_it_works_step_2.svg";
import how_it_works_step_3 from "../../assets/images/how_it_works/how_it_works_step_3.svg";
import how_it_works_step_4 from "../../assets/images/how_it_works/how_it_works_step_4.svg";

const stepsData = [
    {
        title: "Create your knowledge Base",
        description:
            "Refine your content by selecting a specific knowledge base (like Twitter or Arxiv), optimizing for each platform to align perfectly with your audience’s tone.",
        image: how_it_works_step_1,
    },
    {
        title: "Create Your Campaign Type",
        description:
            "Share campaign goals, desired tone, and audience. Campaigns Planet’s AI crafts tailored content that captures your brand’s essence and resonates effectively with your audience.",
        image: how_it_works_step_2,
    },
    {
        title: "Describe what you want your campaign to be like",
        description: "Our wizard is able to create a post according to your needs.",
        image: how_it_works_step_3,
    },
    {
        title: "Watch AI Create for You",
        description:
            "Sit back as Campaigns Planet generates polished, professional posts tailored to your settings. Each post comes complete with captions, hashtags, and optimized visuals—ready to be customized as needed.",
        image: how_it_works_step_4,
    },
];

const HowItWorksSection = () => {
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentStep((prevStep) => (prevStep + 1) % stepsData.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <section className="px-4 py-16 md:py-24 bg-[#F5F5F5]">
            <h2 className="text-3xl md:text-4xl font-extrabold text-center mb-12 text-[#0A0A0A]">
                How Campaigns Planet Works
            </h2>

            <div className="flex flex-col md:flex-row items-start justify-between gap-8 md:px-[60px]">
                <div className="flex-1 md:max-w-[40%] md:pr-8">
                    {stepsData.map((step, index) => (
                        <div key={index} className={`${index === currentStep
                                ? "my-14"
                                : "my-10"
                            }`}>
                            <h3
                                className={`${index === currentStep
                                        ? "text-[32px] font-bold leading-[34px] text-[#0A0A0A]"
                                        : "text-[22px] font-bold leading-[28px] text-[#0A0A0A]"
                                    } font-poppins`}
                            >
                                {step.title}
                            </h3>

                            <p
                                className={`${index === currentStep
                                        ? "text-[20px] font-medium leading-[26px] text-[#0A0A0A]"
                                        : "text-[14px] font-medium leading-[18px] text-gray-900"
                                    } font-poppins mt-2`}
                            >
                                {step.description}
                            </p>
                        </div>
                    ))}
                </div>

                <div className="flex-1 md:max-w-[60%] flex justify-end place-self-end self-center">
                    <div className="h-[400px] max-h-[400px] flex items-center h-fit">
                        <img
                            src={stepsData[currentStep].image}
                            alt={stepsData[currentStep].title}
                            className="w-full h-auto max-h-full rounded-lg"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorksSection;