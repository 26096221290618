import React from "react";

import { CampaignContext } from "./CampaignContext";
import { useState } from "react";

function CampaignProvider({ children }) {
  const [csvData, setCsvData] = useState([]);
  const [csvErrors, setCsvErrors] = useState([]);

  const contextValue = {
    csvData,
    csvErrors,
    setCsvData,
    setCsvErrors
  };
  return (
    <CampaignContext.Provider value={contextValue}>
      {children}
    </CampaignContext.Provider>
  );
}

export default CampaignProvider;
