import { useState } from "react";
import { observer } from "mobx-react-lite";
import { SectionTab } from "polotno/side-panel";
import { ImagesGrid } from "polotno/side-panel/images-grid";
import { TEMPLATES } from "../../data/Templates";

export function TemplatesSectionBuilder(changeCallback) {
  const TemplatesPanel = observer(({ store }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isLoading, setIsLoading] = useState(false);

    return (
      <div style={{ height: "100%" }}>
        <ImagesGrid
          shadowEnabled={false}
          images={TEMPLATES}
          isLoading={isLoading}
          getPreview={(item) => {
            return item.preview;
          }}
          onSelect={(item) => {
            setIsLoading(true);

            changeCallback(item).then((t) => {
              store.loadJSON(t);
            });

            setIsLoading(false);
          }}
          rowsNumber={1}
        />
      </div>
    );
  });

  const TemplatesSection = {
    name: "custom-templates",
    Tab: (props) => (
      <SectionTab name="Templates" {...props}>
        <span aria-hidden="true" className="bp5-icon bp5-icon-fullscreen">
          <svg
            data-icon="control"
            height="16"
            role="img"
            viewBox="0 0 16 16"
            width="16"
          >
            <path
              d="M13 8H8v5h5V8zm0-5H8v4h5V3zm2-3H1C.45 0 0 .45 0 1v14c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-1 14H2V2h12v12zM7 3H3v10h4V3z"
              fillRule="evenodd"
            ></path>
          </svg>
        </span>
      </SectionTab>
    ),
    Panel: TemplatesPanel,
  };

  return TemplatesSection;
}
