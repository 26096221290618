import React, { useEffect, useState } from 'react';

function PercentageLoader({ isLoading, isImageReady, setIsLoading }) {
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setLoadingPercentage((prev) => {
          if (isImageReady && prev < 100) {
            return Math.min(prev + 5, 100);
          }
          if (!isImageReady && prev < 100) {
            return Math.min(prev + 1, 100);
          }
          if (prev >= 100) {
            clearInterval(interval);
            setIsLoading(false);
            return 100;
          }
          return prev;
        });
      }, isImageReady ? 50 : 150);

      return () => clearInterval(interval);
    }
  }, [isLoading, isImageReady, setIsLoading]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-48 p-4 bg-white rounded-lg shadow-lg md:p-6 dark:bg-gray-800 dark:border-gray-700">
      <p className="text-lg text-gray-500 mb-2">Loading...</p>
      <div className="w-full flex justify-center">
        <div className="w-4/5 h-2 bg-gray-300 rounded-full mb-4 dark:bg-gray-700">
          <div
            className="h-full bg-blue-400 rounded-full"
            style={{ width: `${loadingPercentage}%` }}
          ></div>
        </div>
      </div>
      <p className="text-lg text-gray-500">{loadingPercentage}%</p>
    </div>
  );
}

export default PercentageLoader;