import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaChevronDown,
  FaChevronUp,
  FaPencilAlt,
  FaTrash,
  FaTimesCircle,
  FaSave,
} from "react-icons/fa";

import { formatDateString, removeFirstCharacter, extractPkSk } from "../../utils/common";
import { stateMap } from "../../data/CampaignStates";
import { updateCampaignMetrics } from "../../services/CampaignServices";
import { isEmpty, isValidMetric } from "../../utils/validations";
import * as NotificationService from "../../utils/notificationService";

const CampaignRow = ({ 
  currentCampaign, 
  isSubCampaign, 
  i, 
  editingRow, 
  toggleEditRow, 
  deleteCampaignHandler, 
  expandedRow, 
  toggleExpandRow 
}) => {
  const navigate = useNavigate();
  const isMultiCampaign = currentCampaign.type === "MULTI" && currentCampaign.multi_id === "";
  const isLinkedinPost = currentCampaign.metrics?.social_network === 'linkedin';

  const currentViews = (currentCampaign.status === "PUBLISHED" && !isEmpty(currentCampaign.metrics?.metrics) ) ? currentCampaign.metrics?.metrics?.impression_count : '-';
  const currentReactions = (currentCampaign.status === "PUBLISHED" && !isEmpty(currentCampaign.metrics?.metrics) ) ? currentCampaign.metrics?.metrics?.like_count : '-';
  const currentComments = (currentCampaign.status === "PUBLISHED" && !isEmpty(currentCampaign.metrics?.metrics) ) ? currentCampaign.metrics?.metrics?.reply_count : '-';
  const isEditingRow = editingRow === currentCampaign.id;

  const [currentMetrics, setCurrentMetrics] = useState({
    views: currentViews,
    reactions: currentReactions,
    comments: currentComments,
  });
  const [initialMetrics, setInitialMetrics] = useState({
    views: currentViews,
    reactions: currentReactions,
    comments: currentComments,
  });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const {pkuuid: projectSkuuid, skuuid: campaignSkuuid} = extractPkSk(currentCampaign.id);
  const moveToCurrentCampaign = () => navigate(`/mkt/projects/${projectSkuuid}/campaigns/${campaignSkuuid}/assistant`);

  useEffect(() => {
    setInitialMetrics({
      views: currentViews,
      reactions: currentReactions,
      comments: currentComments,
    });
    setCurrentMetrics({
      views: currentViews,
      reactions: currentReactions,
      comments: currentComments,
    });
  }, [currentViews, currentReactions, currentComments]);

  const handleChange = (e) => {
    let targetValue = e.target.value;
    if (targetValue.length > 0 && targetValue[0] === "0") {
      targetValue = removeFirstCharacter(targetValue);
    }
    setCurrentMetrics({
      ...currentMetrics,
      [e.target.name]: targetValue === "" ? "0" : targetValue,
    });
  };

  const handleCancel = () => {
    setCurrentMetrics(initialMetrics);
    toggleEditRow(null);
  };

  const handleSubmit = async () => {
    try {
      for (const metric in currentMetrics) {
        isValidMetric(currentMetrics[metric])
      }
      await updateCampaignMetrics(currentCampaign.id, currentMetrics);
      NotificationService.notifySuccess("Metrics added successfully");
      toggleEditRow(null);
      setInitialMetrics(currentMetrics);
    } catch (error) {
      console.error("Failed to add metrics: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to add metrics: ${errorMessage}`
      );
    }
  };

  const inputProps = {
    className: "w-10 p-0 pl-1 pr-1 border border-gray-300 rounded",
    // disabled: isLinkedinPost ? false : true,
    min: "0",
    style: {
      WebkitAppearance: 'none',
      MozAppearance: 'textfield'
    },
    onChange: handleChange,
  }

    return (
      <React.Fragment>
        <tr
          className={`hover:bg-light-blue hover:cursor-pointer border-b ${
            isSubCampaign ? "bg-gray-200" : ""
          }`}
        >
          <td
            className={`py-3 px-4 ${isSubCampaign ? "pl-10" : ""} w-60`}
            onClick={moveToCurrentCampaign}
          >
            <div className="h-20 flex-col justify-center items-start gap-1 inline-flex">
              <div className="self-stretch text-black text-lg font-semibold leading-normal tracking-tight">
                {isSubCampaign ? `${i + 1}. ${currentCampaign.title}` : currentCampaign.title}
              </div>
              <div className="self-stretch text-light-gray text-base font-normal leading-snug tracking-tight">
                {formatDateString(currentCampaign.created_at)}
              </div>
            </div>
          </td>
          <td className="py-3 px-4 w-32" onClick={moveToCurrentCampaign}>
            <div
              className={
                "h-6 px-3 rounded justify-start items-center gap-1.5 inline-flex " +
                stateMap[currentCampaign.status].classNameOuter
              }
            >
              <div
                className={
                  "text-center text-sm font-normal leading-tight " +
                  stateMap[currentCampaign.status].classNameInner
                }
              >
                {stateMap[currentCampaign.status].title}
              </div>
            </div>
          </td>
          <td className="py-3 px-4 w-10" onClick={!isEditingRow ? moveToCurrentCampaign : null}>
            {isEditingRow ? 
            (
              <input {...inputProps} value={currentMetrics.views} name="views"/>
            ) : 
            (
              <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
                {currentMetrics.views}
              </span>
            )}
          </td>
          <td className="py-3 px-4 w-10" onClick={!isEditingRow ? moveToCurrentCampaign : null}>
            {isEditingRow ? 
            (
              <input {...inputProps} value={currentMetrics.reactions} name="reactions"/>
            ) : 
            (
              <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
                {currentMetrics.reactions}
              </span>
            )}
          </td>
          <td className="py-3 px-4 w-10" onClick={!isEditingRow ? moveToCurrentCampaign : null}>
            {isEditingRow ? 
            (
              <input {...inputProps} value={currentMetrics.comments} name="comments"/>
            ) : 
            (
              <span className="text-light-gray text-base font-semibold leading-snug tracking-tight">
                {currentMetrics.comments}
              </span>
            )}
          </td>
          <td className="py-8 px-5 w-10">
            {isEditingRow ? (
              <div className="flex justify-start items-center space-x-7">
                <FaTimesCircle 
                  onClick={handleCancel}
                  className="text-gray-600 hover:text-black"
                  size={20}
                />
                <FaSave 
                  onClick={handleSubmit}
                  className="text-gray-600 hover:text-black"
                  size={20}
                />
              </div>
            )
            : 
            (
              <div className="flex justify-start items-center space-x-7">
                <button
                  className="cursor-pointer"
                  disabled={!isLinkedinPost}
                  onClick={() => toggleEditRow(currentCampaign.id)}
                >
                  <FaPencilAlt
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      color: !isLinkedinPost ? 'gray' : 'text-gray-600',
                      cursor: !isLinkedinPost ? 'not-allowed' : 'pointer',
                    }}
                    size={20}
                    className="text-gray-600 hover:text-black"
                  />
                </button>
                {isHovered && !isLinkedinPost && (
                  <div className="absolute top-12 left-1/2 transform -translate-x-1/2 p-2 bg-gray-800 text-white rounded shadow">
                    Not allowed for non-LinkedIn posts
                  </div>
                )}
                <div
                  onClick={() => deleteCampaignHandler(currentCampaign.id)}
                  className="cursor-pointer"
                >
                  <FaTrash
                    size={20}
                    className="text-gray-600 hover:text-red-600"
                  />
                </div>
              </div>
            )}
          </td>
          <td className="py-8 px-5 w-20">
            {isMultiCampaign ? (
              <div
                onClick={() => {
                  if (currentCampaign.sub_campaigns && currentCampaign.sub_campaigns.length > 0) {
                    toggleExpandRow(currentCampaign.id);
                  }
                }}
                className={`cursor-pointer ${
                  !currentCampaign.sub_campaigns || currentCampaign.sub_campaigns.length === 0 ? 'cursor-not-allowed opacity-50' : ''
                }`}
              >
                {expandedRow === currentCampaign.id ? (
                  <FaChevronUp size={20} />
                ) : (
                  <FaChevronDown size={20} />
                )}
              </div>
            ) : (
              <div className="invisible">
                <FaChevronDown size={20} />
              </div>
            )}
          </td>
        </tr>
      </React.Fragment>
    );
  };

export default CampaignRow;
