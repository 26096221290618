
export const validateInput = (input, field, setErrors, errorMessage) => {
    if (!input.trim()) {
      const resultErrorMessage = errorMessage || `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: resultErrorMessage,
      }));
      return resultErrorMessage;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
      return null;
    }
};

export function isEmpty(obj) {
  if (obj === null || obj === undefined) {
    return true;
  }
  return Object.keys(obj).length === 0;
}


export function isValidMetric(value) {
  if (typeof value === 'string' && isNaN(Number(value))) {
    throw new Error("Metrics cannot be letters");
  }

  if (!Number.isInteger(Number(value))) {
    throw new Error("Metrics cannot be a decimal number");
  }

  if (Number(value) < 0) {
    throw new Error("Metrics cannot be negative");
  }

  return true;
}
