import React from 'react'
import { Modal } from "flowbite-react";
import Button from "../common/Button";

export default function DeleteKnowledgeBaseConfirmationModal({
    showModal,
    setOpenModal,
    confirmDelete,
}) {
    
    return (
        <Modal show={showModal} onClose={() => setOpenModal(false)}>
            <Modal.Body className="flex flex-col justify-center items-center space-y-4">
                <div className="text-center font-bold">Warning!</div>
                <div className="text-center">
                    Deleting this Knowledge Base is permanent. Are you sure you want to
                    delete this Knowledge Base?
                </div>
                <div className="flex space-x-3">
                    <Button outline onClick={() => setOpenModal(false)}>
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete}>Delete</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
