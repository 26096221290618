class AIModelType {
  static OPEN_AI = "OPEN_AI";
  static GROQ_AI = "GROQ_AI";
  static NO_SELECTED = "NO_SELECTED";

  static toReadable(type) {
    switch (type) {
      case this.OPEN_AI:
        return "OpenAI";
      case this.GROQ_AI:
        return "GroqAI";
      case this.NO_SELECTED:
        return "Select AI Model *"
      default:
        return null;
    }
  }

  static getTypeFromString(typeString) {
    switch (typeString) {
      case "OPEN_AI":
        return this.OPEN_AI;
      case "GROQ_AI":
        return this.GROQ_AI;
      default:
        return null;
    }
  }

  static getAiModelOptions() {
    return [this.OPEN_AI, this.GROQ_AI];
  }
  
}

export default AIModelType;