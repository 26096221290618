import React from "react";

function ErrorMessage({ icon, errorTitle, message }) {
  return (
    <>
      <div className="flex gap-1 pt-2">
        {icon}
        <span className="text-[#FF4144] text-xs">{errorTitle}</span>
      </div>
      <div className="flex gap-1 border-b border-gray-300 pb-2">
        <span className="text-[#FF4144] text-xs">Error:</span>
        <span className="text-xs">{message}</span>
      </div>
    </>
  );
}

export default ErrorMessage;
