
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import ProjectCard from './ProjectCard';
import * as NotificationService from "../../utils/notificationService";
import { ADD_BLUE_ICON_URL } from '../../config/ExternalResources';
import {
    getMyProjects
} from "../../services/index";
import ProjectModal from "./ProjectModal";

function ProjectsPage() {
    const [showModal, setShowModal] = useState(false);

    const myProjectsQuery = useQuery({
        queryKey: ["myProjects"],
        queryFn: getMyProjects,
        onError: (error) => {
            NotificationService.notifyError(`Failed to fetch projects: ${error.message}`);
        },
        staleTime: 60 * 1000,
    });

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (<>
        <div className="flex flex-col rounded-md shadow shadow-yet-yet-another-gray">
            <div className="flex flex-col self-stretch px-6 pt-4 pb-3 bg-white rounded-md border-b-gray-100 max-md:px-5">
                <div className="flex justify-between items-center w-full text-2xl font-bold leading-none text-black min-h-[44px] max-md:max-w-full">
                    <div className="flex-1 shrink-0 my-auto w-full min-w-[240px] max-md:max-w-full">
                        Projects
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-row rounded-md shadow p-5 shadow-yet-yet-another-gray">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
                        <div
                            onClick={openModal}
                            className="flex flex-col justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full cursor-pointer"
                        >
                            <img
                                loading="lazy"
                                src={ADD_BLUE_ICON_URL}
                                className="object-contain self-center w-12 aspect-square"
                                alt="Create prompt template"
                            />
                            <div className="mt-5 w-full text-center font-bold text-[#1DA1F2]">
                                Create Project
                            </div>
                            <div className="mt-5 w-full text-center">
                                Create a new project for <br /> organize your campaigns
                            </div>
                        </div>
                        {myProjectsQuery.isLoading ? (
                            <div className="flex flex-col items-center justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full">
                                <div className="spinner"></div>
                            </div>
                        ) : myProjectsQuery.data?.length > 0 ? (
                            myProjectsQuery.data.map((project) => (
                                <ProjectCard
                                    key={`ProjectCard-${project.id}`}
                                    project={project}
                                />
                            ))
                        ) : (
                            <div className="flex flex-col items-center justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full">
                                <div className="text-center text-gray-500">
                                    No projects found.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        {showModal && <ProjectModal closeModal={closeModal} />}
    </>);
}

export default ProjectsPage;