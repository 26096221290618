class SocialNetworkType {
    static X = "X";
    static LINKEDIN = "LinkedIn";
  
    static toReadable(type) {
      switch (type) {
        case this.X:
          return 'X';
        case this.LINKEDIN:
          return "LinkedIn";
        default:
          return null;
      }
    }
  }
  
  export default SocialNetworkType;