import { useContext } from "react";
import { CampaignContext } from "../../context/campaigns/CampaignContext";

const useCampaignContext = () => {
  const context = useContext(CampaignContext);
  if (!context) {
    throw new Error("useClientContext must be used within a ClientProvider");
  }

  return context;
};

export default useCampaignContext;
