import React, { useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const PromptTemplatesDisplayer = ({ handlePromptChange, promptTemplates }) => {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  const existPromptTemplates = promptTemplates.length > 0;

  return (
    <div className="relative w-full flex justify-center items-center">
      {existPromptTemplates && (
        <button
          className="bg-white p-2 rounded-full shadow-md hover:bg-gray-100 focus:outline-none absolute left-0 z-10"
          onClick={scrollLeft}
        >
          <FaChevronLeft className="h-6 w-6 text-gray-700" />
        </button>
      )}
      <div className="relative w-10/12 place-self-center overflow-hidden">
        <div
          ref={scrollContainerRef}
          className="flex gap-4 overflow-x-auto no-scrollbar scroll-smooth snap-x snap-mandatory px-8"
        >
          {existPromptTemplates ? (
            promptTemplates.map((template) => {
              const truncatedContent =
                template.prompt_template_content.length > 100
                  ? template.prompt_template_content.substring(0, 100) + "..."
                  : template.prompt_template_content;
              return (
                <div
                  key={template.prompt_id}
                  onClick={() => handlePromptChange(template.prompt_id)}
                  className="flex-shrink-0 w-64 px-4 pt-3 rounded-xl bg-[#F6F9FE] shadow-md cursor-pointer snap-start"
                  title={template.prompt_template_content}
                >
                  <h3 className="text-lg font-bold leading-loose text-gray-800 overflow-hidden truncate break-words">
                    {template.prompt_template_name}
                  </h3>
                  <p className="mt-2 text-xs tracking-wide leading-5 text-gray-600 break-words">
                    {truncatedContent}
                  </p>
                </div>
              );
            })
          ) : (
            <div className="text-center text-gray-500 w-full">
              No user prompt templates found.
            </div>
          )}
        </div>
      </div>
      {existPromptTemplates && (
        <button
          className="bg-white p-2 rounded-full shadow-md hover:bg-gray-100 focus:outline-none absolute right-0 z-10"
          onClick={scrollRight}
        >
          <FaChevronRight className="h-6 w-6 text-gray-700" />
        </button>
      )}
    </div>
  );
};

export default PromptTemplatesDisplayer;
