import React from "react";
import template_preview_1 from "../../assets/images/template_previews/template_preview_1.svg";
import template_preview_2 from "../../assets/images/template_previews/template_preview_2.svg";
import template_preview_3 from "../../assets/images/template_previews/template_preview_3.svg";
import template_preview_4 from "../../assets/images/template_previews/template_preview_4.svg";
import template_preview_5 from "../../assets/images/template_previews/template_preview_5.svg";

const templatesData = [
    { img: template_preview_1, alt: "Marketing Template" },
    { img: template_preview_2, alt: "Gym Center Template" },
    { img: template_preview_3, alt: "Holiday Template" },
    { img: template_preview_4, alt: "Real Estate Template" },
    { img: template_preview_5, alt: "Black Friday Template" },
];

const TemplatesShowcase = () => {
    return (
        <section
            className="px-4 py-16 md:py-24"
            style={{
                background:
                    "radial-gradient(circle at -25% 50%, #5638FA 0%, #ED499B 47%, #F7AC32 100%)",
            }}
        >
            <style>
                {`
                    @keyframes scroll {
                        0% {
                            transform: translateX(0);
                        }
                        100% {
                            transform: translateX(-50%);
                        }
                    }

                    .scrolling-carousel {
                        animation: scroll 20s linear infinite;
                    }

                    .scrolling-carousel:hover {
                        animation-play-state: paused;
                    }
                `}
            </style>

            <h2 className="text-3xl md:text-4xl font-extrabold text-center text-white mb-12">
                Discover Customizable Templates for Every Need
            </h2>

            <div className="relative w-full overflow-hidden">
                <div className="flex gap-[30px] scrolling-carousel">
                    {templatesData.concat(templatesData).map((template, index) => (
                        <div
                            key={index}
                            className="min-w-[220px] md:min-w-[300px] rounded-lg shadow-lg overflow-hidden"
                        >
                            <img
                                src={template.img}
                                alt={template.alt}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TemplatesShowcase;
