import StatusType from "./enums/statusType";

const PUBLISHED = StatusType.PUBLISHED;
const DRAFT = StatusType.DRAFT;
const PROCESSING = StatusType.PROCESSING;
const IN_REVIEW = StatusType.IN_REVIEW
const DELETED = StatusType.DELETED;
const ERROR = StatusType.ERROR

export const stateMap = {
    PUBLISHED: {
      'title':  StatusType.toReadable(PUBLISHED),
      'classNameOuter': 'bg-green-100',
      'classNameInner': 'text-green-500',
    },
    DRAFT: {
      'title': StatusType.toReadable(DRAFT),
      'classNameOuter': 'bg-yellow-100',
      'classNameInner': 'text-yellow-500',
    },
    ERROR: {
      'title': ERROR,
      'classNameOuter': 'bg-red-100',
      'classNameInner': 'text-red-500',
    },
    DELETED: {
      'title': DELETED,
      'classNameOuter': 'bg-red-100',
      'classNameInner': 'text-red-500',
    },
    PROCESSING: {
      'title': StatusType.toReadable(PROCESSING),
      'classNameOuter': 'bg-blue-400',
      'classNameInner': 'text-white',
    },
    IN_REVIEW: {
      'title': StatusType.toReadable(IN_REVIEW),
      'classNameOuter': 'bg-orange-400',
      'classNameInner': 'text-white',
    }
};
