import React, { useState } from "react";
import CampaignRow from "./CampaignRow";

function CampaignsTable({ campaigns, deleteCampaignHandler }) {
  const [expandedRow, setExpandedRow] = useState(null);
  const toggleExpandRow = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const [editingRow, setIsEditingRow] = useState(null);
  const toggleEditRow = (id) => {
    setIsEditingRow(editingRow === id ? null : id);
  };
  
  const listCampaigns = (campaignList = campaigns, isSubCampaign = false) => {
    return campaignList.map((c, i) => {
        return (
        <React.Fragment key={c.id}>
          <CampaignRow
            currentCampaign={c} 
            editingRow={editingRow} 
            isSubCampaign={isSubCampaign} 
            i={i} 
            key={c.id || i}
            deleteCampaignHandler={deleteCampaignHandler}
            expandedRow={expandedRow}
            toggleEditRow={toggleEditRow}
            toggleExpandRow={toggleExpandRow}
          />   
          {expandedRow === c.id && c.sub_campaigns && (
             <React.Fragment key={`sub-${c.id}`}>
               {listCampaigns(c.sub_campaigns, true)}
             </React.Fragment>
          )}
        </React.Fragment>)
        
      });
  };

  return (
    <table className="max-md:table-fixed overflow-auto w-full">
      <thead className="bg-yet-another-gray text-light-gray font-semibold m-4">
        <tr>
          <td className="py-3 px-4 w-60">Title</td>
          <td className="py-3 px-4 w-32">Status</td>
          <td className="py-3 px-4 w-10">Views</td>
          <td className="py-3 px-4 w-10">Reactions</td>
          <td className="py-3 px-4 w-10">Comments</td>
          <td className="py-3 px-4 w-10">Actions</td>
          <td className="py-3 px-4 w-20"></td>
        </tr>
      </thead>
      <tbody>{listCampaigns()}</tbody>
    </table>
  );
}

export default CampaignsTable;