import React from "react";

const AddDataFilterTitle = () => {
    return (
        <div className="flex flex-col flex-1 justify-center items-center w-full max-w-full">
            <h1 className="text-2xl tracking-wide leading-none text-neutral-900">
                Add Data Filter
            </h1>
            <div className="mt-2 text-base leading-6 text-neutral-900 text-opacity-80 text-center">
                Now, let's add one or more Data Filters. Data Filters
                will work like search tools helping you pinpoint
                exactly what you need.
            </div>
        </div>
    );
};

export default AddDataFilterTitle;
