import React from "react";

import key_feature_1 from "../../assets/images/features/key_feature_1.svg";
import key_feature_2 from "../../assets/images/features/key_feature_2.svg";
import key_feature_3 from "../../assets/images/features/key_feature_3.svg";
import key_feature_4 from "../../assets/images/features/key_feature_4.svg";
import key_feature_5 from "../../assets/images/features/key_feature_5.svg";

const featuresData = [
    {
        title: "Create Multiple Campaigns",
        description:
            "Transform ideas into single or multiple posts with 5,000+ multimedia options. Manage scheduling and publishing effortlessly.",
        image: key_feature_1,
        reverse: true,
    },
    {
        title: "Premium Stock Assets",
        description:
            "Stand out with high-quality, AI-curated stock images and videos, tailored to your industry, audience, and campaign goals.",
        image: key_feature_2,
        reverse: false,
    },
    {
        title: "Professional Editing Tools",
        description:
            "Refine your post with our intuitive editor. Adjust fonts, colors, layouts, or add custom icons to personalize your content.",
        image: key_feature_3,
        reverse: true,
    },
    {
        title: "Post or Schedule",
        description:
            "Reach your audience at optimal times with Campaigns Planet’s integrated scheduler, compatible with X (Twitter) and LinkedIn.",
        image: key_feature_4,
        reverse: false,
    },
    {
        title: "Metrics Dashboard",
        description:
            "A central hub for tracking and visualizing key performance metrics of your posts.",
        image: key_feature_5,
        reverse: true,
    },
];

const KeyFeaturesSection = () => {
    return (
        <section className="px-4 py-16 md:py-24 bg-white md:max-w-[80%] mx-auto scroll-mt-[80px]" id="features">
            <h2 className="text-3xl md:text-4xl font-extrabold text-center mb-12 text-[#0A0A0A]">
                Key Features
            </h2>

            <div className="grid grid-cols-1 gap-12">
                {featuresData.map((feature, index) => (
                    <div
                        key={index}
                        className={`flex flex-col md:flex-row ${feature.reverse ? "md:flex-row-reverse" : "md:flex-row"
                            } items-center gap-x-[170px]`}
                    >
                        <div className="flex-1 order-1 md:order-none">
                            <h3 className="text-[32px] font-bold mb-4 text-[#121212] font-poppins">
                                {feature.title}
                            </h3>
                            <p className="text-[18px] text-[#121212] font-poppins">{feature.description}</p>
                        </div>

                        <div className="flex-1 order-2 md:order-none">
                            <img
                                src={feature.image}
                                alt={feature.title}
                                className="w-full h-auto"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default KeyFeaturesSection;