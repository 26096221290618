import { useReducer, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import Papa from "papaparse";

import ErrorMessage from "./ErrorMessage";
import { ReactComponent as UploadIcon } from "../../assets/upload_files_icon.svg";
import { ReactComponent as ArrowUp } from "../../assets/arrow_up.svg";
import { ReactComponent as TriangleExclamation } from "../../assets/triangle_exclamation.svg";
import { ReactComponent as CircleCheck } from "../../assets/circle_check.svg";
import { ReactComponent as CircleExclamation } from "../../assets/circle_exclamation.svg";
import { initialExpandedSections } from "../../config/constants";
import useCampaignContext from "../../hooks/Campaign/useCampaignContext";

const reducer = (state, action) => {
  const actions = {
    warning: !state.warning,
    error: !state.error,
    success: !state.success,
    resetState: initialExpandedSections,
  };

  const isExpandible = actions[action.type];

  if (action.type === "resetState") {
    return actions[action.type];
  }

  return { ...state, [action.type]: isExpandible };
};

function FileUploadForm() {
  const { csvData, csvErrors, setCsvData, setCsvErrors } = useCampaignContext();
  const [fileName, setFileName] = useState(null);
  const [expandedSections, dispatch] = useReducer(
    reducer,
    initialExpandedSections
  );
  const dataLength = csvData.length;
  const fileErrorsLength = csvErrors.length;
  const isFileError = fileErrorsLength > 0;
  const isFileSuccessful = fileErrorsLength === 0 && dataLength > 0;

  const handleFileChange = (e) => {
    dispatch({ type: "resetState" });

    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const data = results.data;
          if (data.length > 0) {
            setCsvErrors([]);
            setCsvData(data);
            validateCsvData(data);
          }
        },
      });
    }
  };

  const handleExpansion = (action) => {
    dispatch({ type: action });
  };

  const validateCsvData = (data) => {
    const requireFields = ["campaign_name", "prompt", "ai_model"];

    data.forEach((row, idx) => {
      const allFieldsPresent = requireFields.every((field) => row[field]);
      if (!allFieldsPresent) {
        setCsvErrors((prev) => [
          ...prev,
          {
            idx: idx,
            message: `The fields ${requireFields.join(", ")} are required`,
          },
        ]);
        return;
      }
    });
  };

  const StatusMessage = ({ icon, message, status }) => {
    return (
      <div className="flex gap-1 py-4">
        <div className="flex items-center grow space-x-2">
          {icon}
          <span className="text-[#121212B2] text-sm font-normal">
            {message}
          </span>
        </div>
        <button onClick={() => handleExpansion(status)}>
          {expandedSections[status] ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
    );
  };

  return (
    <div
      className={`
        flex flex-col px-4 
        ${(isFileSuccessful || isFileError) && "pb-4"} 
        border border-gray-300 rounded w-[456px] gap-y-3`}
    >
      <div
        className={`flex py-4 ${
          (isFileSuccessful || isFileError) && "border-b"
        }`}
      >
        <div className="flex items-center grow space-x-2">
          <UploadIcon className="w-8 h-8" />
          <span className="text-sm font-bold">
            {fileName ?? "Upload CSV File"}
          </span>
        </div>
        <label
          htmlFor="fileInput"
          className="bg-[#1DA1F2] text-white text-xs w-[117px] h-[40px] flex items-center justify-center gap-x-2.5 cursor-pointer"
        >
          <ArrowUp className="w-3 h-3 text-white" />
          <>Upload File</>
          <input
            className="hidden"
            id="fileInput"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
        </label>
      </div>

      {isFileError && (
        <StatusMessage
          icon={<TriangleExclamation className="w-4 h-4" />}
          message="Upload Completed with Errors"
          status="warning"
        />
      )}

      {isFileSuccessful && (
        <StatusMessage
          icon={<CircleCheck className="w-4 h-4" />}
          message="Upload Completed successfully"
          status="success"
        />
      )}

      {expandedSections.success && (
        <div className="flex gap-1 py-3 border-b  border-gray-300">
          <span className="text-[#00B93E] grow text-xs font-normal">
            {`Succesfully uploaded: ${dataLength} posts`}
          </span>
        </div>
      )}

      {expandedSections.warning && (
        <>
          <div className="flex gap-1 py-3">
            <span className="text-[#FF4144] grow text-xs font-normal">
              {`Errors encoutered: ${fileErrorsLength} posts`}
            </span>
            <button onClick={() => handleExpansion("error")}>
              {expandedSections.error ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>
        </>
      )}

      {expandedSections.error && (
        <div className="flex flex-col gap-y-1 pb-2">
          {dataLength === fileErrorsLength ? (
            <ErrorMessage
              icon={<CircleExclamation className="w-[14px] h-[14px]" />}
              errorTitle="File"
              message="The csv does not macth the template format"
            />
          ) : (
            csvErrors.map((file) => (
              <ErrorMessage
                key={`${file.idx}-${file.message}`}
                icon={<CircleExclamation className="w-[14px] h-[14px]" />}
                errorTitle={`Line ${file.idx + 1}`}
                message={file.message}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default FileUploadForm;
