import React from "react";
import { Modal } from "flowbite-react";
import Button from "../common/Button";

const DeletePromptTemplateConfirmationModal = ({
    showModal,
    setOpenModal,
    confirmDelete,
}) => {
    return (
        <Modal show={showModal} onClose={() => setOpenModal(false)}>
            <Modal.Header className="text-center">Delete Prompt template</Modal.Header>
            <Modal.Body>
                <div className="flex flex-col justify-center items-center space-y-4">
                    <div className="text-center">
                        Are you sure you want to delete this Prompt?
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="w-full flex-row justify-end space-x-3">
                    <Button outline onClick={() => setOpenModal(false)}>Cancel</Button>
                    <Button onClick={confirmDelete}>Delete</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default DeletePromptTemplateConfirmationModal;
