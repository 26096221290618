import NewTemplate from "./NewTemplate";
import EditTemplate from "./EditTemplate";
import { useEffect, useState } from "react";
import { ADD_BLUE_ICON_URL } from '../../config/ExternalResources';
import DeletePromptTemplateConfirmationModal from './DeletePrompTemplateConfirmationModal';
import PromptTemplateCard from './PrompTemplateCard';
import * as NotificationService from "../../utils/notificationService";
import {
  createPromptTemplate,
  fetchPromptTemplates,
  deletePromptTemplate,
  fetchSystemPromptTemplates
} from "../../services/index";

function PromptTemplate() {
  const [activeTab, setActiveTab] = useState("Your Prompt Templates");
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userPromptTemplates, setUserPromptTemplates] = useState([]);
  const [systemPromptTemplates, setSystemPromptTemplates] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingUserTemplates, setIsLoadingUserTemplates] = useState(true);
  const [isLoadingSystemTemplates, setIsLoadingSystemTemplates] = useState(true);

  const openModal = () => setShowModal(true);
  const hideDropdown = () => setActiveDropdown(null);

  const closeModal = () => {
    fetchUserPromptTemplatesHandler();
    setShowModal(false);
  };

  const openEditModal = (template) => {
    setCurrentTemplate(template);
    setShowEditModal(true);
  };
  const closeEditModal = () => {
    setCurrentTemplate(null);
    fetchUserPromptTemplatesHandler();
    setShowEditModal(false);
  };

  const toggleDropdown = (templateId) => {
    setActiveDropdown((prevId) => (prevId === templateId ? null : templateId));
  };

  const fetchUserPromptTemplatesHandler = async () => {
    setIsLoadingUserTemplates(true);
    try {
      const data = await fetchPromptTemplates();
      setUserPromptTemplates(data.data);
    } catch (error) {
      console.error("Failed to fetch user prompt templates: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(`Failed to fetch user prompt templates: ${errorMessage}`);
    } finally {
      setIsLoadingUserTemplates(false);
    }
  };

  const fetchSystemPromptTemplatesHandler = async () => {
    setIsLoadingSystemTemplates(true);
    try {
      const data = await fetchSystemPromptTemplates();
      setSystemPromptTemplates(data.data);
    } catch (error) {
      console.error("Failed to fetch system prompt templates: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(`Failed to fetch system prompt templates: ${errorMessage}`);
    } finally {
      setIsLoadingSystemTemplates(false);
    }
  };

  const onDuplicate = async (Name, Desc, id) => {
    try {
      await createPromptTemplate(Name, Desc, Name);
      NotificationService.notifySuccess("Prompt template duplicated successfully");
      fetchUserPromptTemplatesHandler();
    } catch (error) {
      console.error("Failed to duplicate prompt template: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(`Failed to duplicate prompt template: ${errorMessage}`);
    }
  };

  const confirmDelete = async () => {
    try {
      await deletePromptTemplate(deletingId);
      NotificationService.notifySuccess("Prompt template deleted successfully");
      fetchUserPromptTemplatesHandler();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Failed to delete prompt template: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(`Failed to delete prompt template: ${errorMessage}`);
    }
  };

  useEffect(() => {
    fetchUserPromptTemplatesHandler();
    fetchSystemPromptTemplatesHandler();
  }, []);

  const onClickEditOption = (template) => {
    openEditModal(template);
    hideDropdown();
  };

  const onClickDuplicateOption = (template) => {
    onDuplicate(
      template.prompt_template_name,
      template.prompt_template_content,
      template.prompt_id
    );
    hideDropdown();
  };

  const onClickDeleteOption = (template) => {
    setDeletingId(template.prompt_id);
    setIsModalOpen(true);
    hideDropdown();

  };

  return (
    <div className="flex flex-col rounded-md shadow shadow-yet-yet-another-gray">
      <div className="flex flex-col self-stretch px-6 pt-4 pb-3 bg-white rounded-md border-b-gray-100 max-md:px-5">
        <div className="flex justify-between items-center w-full text-2xl font-bold leading-none text-black min-h-[44px] max-md:max-w-full">
          <div className="flex-1 shrink-0 my-auto w-full min-w-[240px] max-md:max-w-full">
            Prompt Templates
          </div>
        </div>
        <div className="flex relative gap-6 mt-4 border-b border-solid text-base font-semibold tracking-tight text-center">
          <div
            className={`z-0 text-sky-500 cursor-pointer ${
              activeTab === "Your Prompt Templates"
                ? "text-[#1DA1F2] border-b-[3px] border-main-blue"
                : "text-[gray]"
            }`}
            onClick={() => setActiveTab("Your Prompt Templates")}
          >
            Your Prompt Templates
          </div>
          <div
            className={`z-0 text-slate-500 cursor-pointer ${
              activeTab === "System Prompt Templates"
                ? "text-[#1DA1F2] border-b-[3px] border-main-blue"
                : "text-[gray]"
            }`}
            onClick={() => setActiveTab("System Prompt Templates")}
          >
            System Prompt Templates
          </div>
        </div>
      </div>
      <div>
        {activeTab === "Your Prompt Templates" && (
          <div className="flex flex-row rounded-md shadow p-5 shadow-yet-yet-another-gray">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
              <div
                onClick={openModal}
                className="flex flex-col justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full cursor-pointer"
              >
                <img
                  loading="lazy"
                  src={ADD_BLUE_ICON_URL}
                  className="object-contain self-center w-12 aspect-square"
                  alt="Create prompt template"
                />
                <div className="mt-5 w-full text-center font-bold text-[#1DA1F2]">
                  Create Prompt Template
                </div>
                <div className="mt-5 w-full text-center">
                  Create a new prompt for <br /> customized search
                </div>
              </div>
              {isLoadingUserTemplates ? (
                <div className="flex flex-col items-center justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full">
                  <div className="spinner"></div>
                </div>
              ) : userPromptTemplates.length > 0 ? (
                userPromptTemplates.map((userTemplate) => (
                  <PromptTemplateCard
                    key={userTemplate.prompt_id}
                    selectedPromptId={activeDropdown}
                    template={userTemplate}
                    onClickOptions={toggleDropdown}
                    onClickEditOption={onClickEditOption}
                    onClickDuplicateOption={onClickDuplicateOption}
                    onClickDeleteOption={onClickDeleteOption}
                  />
                ))
              ) : (
                <div className="flex flex-col items-center justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full">
                  <div className="text-center text-gray-500">
                    No user prompt templates found.
                  </div>
                </div>
              )}
            </div>

            {showModal && <NewTemplate closeModal={closeModal} />}
            {showEditModal && currentTemplate && (
              <EditTemplate
                Name={currentTemplate.prompt_template_name}
                Desc={currentTemplate.prompt_template_content}
                Id={currentTemplate.prompt_id}
                Source={currentTemplate.prompt_template_reference}
                closeModal={closeEditModal}
              />
            )}
          </div>
        )}

        {activeTab === "System Prompt Templates" && (
          <div className="flex flex-row rounded-md shadow p-5 shadow-yet-yet-another-gray">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
              {isLoadingSystemTemplates ? (
                <div className="flex flex-col items-center justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full">
                  <div className="spinner"></div>
                </div>
              ) : systemPromptTemplates.length > 0 ? (
                systemPromptTemplates.map((systemTemplate) => (
                  <PromptTemplateCard
                    key={systemTemplate.prompt_id}
                    selectedPromptId={activeDropdown}
                    template={systemTemplate}
                    onClickOptions={toggleDropdown}
                    onClickEditOption={onClickEditOption}
                    onClickDuplicateOption={onClickDuplicateOption}
                    onClickDeleteOption={onClickDeleteOption}
                    isSystem
                  />
                ))
              ) : (
                <div className="flex flex-col items-center justify-center bg-white rounded-lg min-h-[220px] shadow-lg w-full">
                  <div className="text-center text-gray-500">
                    No system prompt templates found.
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <DeletePromptTemplateConfirmationModal
        showModal={isModalOpen}
        setOpenModal={setIsModalOpen}
        confirmDelete={confirmDelete}
      />
    </div>
  );
}

export default PromptTemplate;