import React from "react";
import { useQuery } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";

import { ReactComponent as CampaignIcon } from "../../assets/campaign.svg";
import { extractPkSk, truncateContent } from "../../utils/common";
import LocalStorageKeys from "../../data/enums/localStorageKeys";
import {
    getMyProjects,
} from "../../services";

function ProjectsList() {
    const myProjectsQuery = useQuery({
        queryKey: ["myProjects"],
        queryFn: getMyProjects,
        staleTime: 60 * 1000,
    });

    const sideBarNavLinkStyles = ({ isActive }) => {
        return isActive
            ? "self-stretch h-12 px-4 bg-indigo-50 text-main-blue rounded-lg justify-start items-center gap-3 inline-flex"
            : "self-stretch h-12 px-4 justify-start items-center gap-3 inline-flex";
    };

    return (
        <ul className="mt-2 pl-4 list-none text-gray-700 flex flex-col max-h-36 overflow-y-auto no-scrollbar">
            {myProjectsQuery.data?.map((pj) => {
                const { skuuid } = extractPkSk(pj.id);
                return (
                    <NavLink
                        key={`projects-link-${pj.id}`}
                        to={`projects/${skuuid}/campaigns`}
                        onClick={() => {
                            localStorage.setItem(LocalStorageKeys.PROJECT_ID_KEY, pj.id);
                        }}
                        className={() => sideBarNavLinkStyles(false)}
                    >
                        <CampaignIcon />
                        <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
                            <div className="self-stretch text-base font-normal leading-normal tracking-wide">
                                {truncateContent(pj.title, 10).truncatedText}
                            </div>
                        </div>
                    </NavLink>
                );
            })}
        </ul>
    );
}

export default ProjectsList;