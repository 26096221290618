import { useContext } from "react"

import { AuthContext } from './AuthProvider'

import LoginBg from './assets/login_bg.webp'
import { ReactComponent as Logo } from './assets/logos/campaigns-planet-logo.svg'
import { ReactComponent as GoogleLogo } from './assets/google_logo.svg'
import { READABLE_SITE_NAME } from './config/constants'
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate();

  if (auth.user) {
    return
  }

  return (
      <div className="w-screen h-screen bg-white flex flex-row">
        <img className="hidden md:block w-full md:w-1/2 2xl:w-2/3 h-screen object-cover" src={LoginBg} alt="robot, backround"/>
        <div className="w-full md:w-1/2 2xl:w-1/3 h-screen px-4 py-8 bg-gradient-to-br from-orange-100 via-light-blue to-blue-100 flex-col justify-between items-center inline-flex z-10">
          <div className="self-stretch grow shrink basis-0 flex-col justify-center items-center gap-8 flex">
            <Logo className="text-main-blue"/>
            <div className="self-stretch h-16 flex-col justify-start items-start gap-1 flex">
              <div className="self-stretch text-center text-black text-[32px] font-semibold leading-10 tracking-tight">Welcome back!</div>
              <div className="self-stretch text-center text-neutral-700 text-base font-normal leading-tight">Sign in to continue</div>
            </div>
            <button onClick={auth.signin} className="self-stretch h-11 mx-auto px-4 bg-white shadow rounded-3xl justify-center items-center gap-4 inline-flex">
              <GoogleLogo/>
              <div className="text-center text-black font-semibold text-lg leading-tight">
                Sign in with Google
              </div>
            </button>
          </div>
          <div className="self-stretch text-center text-light-gray text-opacity-90 text-sm font-normal leading-tight tracking-tight">
            By creating your account with {READABLE_SITE_NAME} you agree to the Terms & Privacy Policy
          </div>
        </div>
        <div 
          onClick={() => navigate("../")}
          className="left-8 top-8 absolute justify-start items-center gap-3 inline-flex hover:cursor-pointer"
        >
          <Logo/>
          <div className="text-white text-2xl font-semibold leading-tight">{READABLE_SITE_NAME}</div>
        </div>
      </div>
  )
}

export default LoginPage
