import { useState } from "react";
import {
  socialPlatformDisconnectCallbacksMap,
  getSocialPlatformIcon,
  getSocialPlatformName,
} from "../../utils/socialPlatformUtils";

import Button from "../common/Button";
import AddSocialAccountModal from "./AddSocialAccountModal";
import { ReactComponent as MoreVertIcon } from '../../assets/more_vert.svg';

export default function SocialAccountsTable({
  apps,
  isLoading,
  setIsLoading,
  fetchApps,
}) {
  const [openOptions, setOpenOptions] = useState(null);
  const [openAddAccountModal, setOpenAddAccountModal] = useState(false);

  const toggleOptionsHandler = (id) => {
    setOpenOptions(openOptions === id ? null : id);
  };

  return (
    <div className="w-full bg-white">
      <table className="min-w-full bg-white border-separate border-spacing-y-2">
        <thead>
          <tr className="bg-[#F1F6F8]">
            <th className="px-4 text-left text-gray-600">Social Media Account</th>
            <th className="px-4 text-left text-gray-600">Account Name</th>
            <th className="px-4 text-left text-gray-600">Status</th>
            <th className="px-4 text-left text-gray-600">Actions</th>
            <th className="text-right">
              <Button
                className="bg-blue-500 text-white py-2 px-4 w-36"
                style={{ borderRadius: "4px" }}
                onClick={() => setOpenAddAccountModal(true)}
              >
                Add Account
              </Button>
              <AddSocialAccountModal
                openModal={openAddAccountModal}
                setOpenModal={setOpenAddAccountModal}
              />
            </th>
          </tr>
        </thead>

        {isLoading && apps.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={5} className="text-center py-4">
                <div className="text-gray-600">Loading...</div>
              </td>
            </tr>
          </tbody>
        ) : apps.length === 0 && !isLoading ? (
          <tbody>
            <tr>
              <td colSpan={5} className="text-center py-4">
                <div className="text-gray-600">No social accounts found</div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {apps.map((app, index) => (
              <tr key={index} className="shadow">
                <td className="py-4 px-4 flex items-center space-x-2">
                  {getSocialPlatformIcon(app.app)}
                  <span className="font-gilroy-bold text-[18px] font-normal leading-6 tracking-[0.15px] text-left">
                    {getSocialPlatformName(app.app)}
                  </span>
                </td>
                <td className="py-4 px-4">
                  <span>{app.account_name ?? "Not provided"}</span>
                </td>
                <td className="py-4 px-4">
                  <span className="text-green-700 px-4 py-2 rounded bg-[#CFFFD2]">
                    Active
                  </span>
                </td>
                <td className="py-4 px-8 relative">
                  <div
                    className="flex justify-left items-center"
                    onClick={() => toggleOptionsHandler(app.app)}
                  >
                    <MoreVertIcon />
                  </div>
                  {openOptions === app.app && (
                    <div className="absolute z-10 right-0 mt-2 w-48 bg-white rounded-lg shadow-lg">
                      <div className="py-1">
                        <div
                          onClick={() => {
                            setOpenOptions(null);
                            socialPlatformDisconnectCallbacksMap[
                              getSocialPlatformName(app.app)
                            ]?.(app.app, setIsLoading, fetchApps);
                          }}
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                        >
                          Disconnect
                        </div>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
}