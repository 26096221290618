import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";

export const createPromptTemplate = async (promptName, promptDescription, promptSource) => {
  try {
    const session = await fetchAuthSession();
    const newTemplate = {
      data_template: {
        prompt_template_name: promptName,
        prompt_template_content: promptDescription,
        ...(promptSource && { prompt_template_reference: promptSource }),
      },
    };

    const response = await fetch(
      `${API_SERVER}/prompt-template/user`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(newTemplate),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Error createPromptTemplate:", error);
    throw error;
  }
};

export const fetchPromptTemplates = async () => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/prompt-template/user`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Error fetchPromptTemplates:", error);
    throw error;
  }
};

export const updatePromptTemplate = async (promptId, promptName, promptDescription, promptSource) => {
  try {
    const session = await fetchAuthSession();
    const newTemplate = {
      data_template: {
        prompt_id: promptId,
        prompt_template_name: promptName,
        prompt_template_content: promptDescription,
        // ...(promptSource && { prompt_template_reference: promptSource }),
      },
    };
    const response = await fetch(
      `${API_SERVER}/prompt-template/user`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(newTemplate),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Error updating template:", error);
    throw error;
  }
};

export const deletePromptTemplate = async (promptTemplateId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/prompt-template/user/${promptTemplateId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Error deletePromptTemplate:", error);
    throw error;
  }
};
