import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { cloneDeep, random } from "lodash";
import { createStore } from "polotno/model/store";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { DEFAULT_SECTIONS } from "polotno/side-panel";
import { Toolbar } from "polotno/toolbar/toolbar";
import { Workspace } from "polotno/canvas/workspace";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { Tooltip } from "polotno/canvas/tooltip";
import { SidePanel } from "polotno/side-panel";

import { ResizeSection } from "../editor/ResizeSection";
import { TemplatesSectionBuilder } from "../editor/TemplatesSection";
import { TEMPLATES } from "../../data/Templates";
import { ActionControlsBuilder } from "../editor/ActionControls";
import { POLOTNO_KEY } from "../../config/constants";
import * as NotificationService from "../../utils/notificationService";
import {
  fetchCampaign,
  updateCampaignPost,
  generateTemplateContent,
  processImage,
  enhanceImage,
} from "../../services";

import Loading from "../common/Loading";

import "@blueprintjs/core/lib/css/blueprint.css";
import { updateElementContent } from "./utils";

const SKIP_DEFAULT_SECTIONS = [
  "templates",
  "size",
  "background",
  "elements",
  "layers",
];

const store = createStore({
  key: POLOTNO_KEY,
  showCredit: true,
});

function PostEditStep() {
  const navigate = useNavigate();
  const { campaignskuuid, projectskuuid } = useParams();

  const queryClient = useQueryClient();
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);

  const campaignResults = useQuery({
    queryKey: [`campaign-${campaignskuuid}`],
    queryFn: async () => fetchCampaign(projectskuuid, campaignskuuid),
    staleTime: 10 * 1000,
  });
  const campaign = campaignResults.data;

  const captionsResults = useQuery({
    queryKey: ["captions"],
    queryFn: async () => {
      if (!campaign?.template_captions) {
        return generateTemplateContent(campaign?.caption, campaign?.id);
      } else {
        try {
          return typeof campaign.template_captions === "string"
            ? JSON.parse(JSON.parse(campaign.template_captions))
            : campaign.template_captions;
        } catch (error) {
          console.error("Error parsing template captions:", error);
          return null;
        }
      }
    },
    staleTime: 10 * 1000,
    enabled: !Boolean(campaign.template_captions),
  });

  const captions = captionsResults.data || {};

  TEMPLATES[0].preview = campaign?.post_image;
  const [currTemplate, setCurrTemplate] = useState(cloneDeep(TEMPLATES[0]));

  const templateChangeCallback = async (template) => {
    const currentTemplateId = template.pages[0].id;
    setIsTemplateLoading(true);
    const _template = cloneDeep(template);
    if (_template) {
      _template.preview = campaign.post_image;
    }
    _template.pages[0]?.children.forEach((element) => {
      updateElementContent(element, campaign, captions, currentTemplateId);
    });
    await finalizeTemplate(_template);

    return _template;
  };

  const enhanceImageMutation = useMutation({
    mutationFn: async () => {
      setIsLoading(true);
      setIsImageReady(false);
      const imageObj = await enhanceImage(campaign.id);
      queryClient.setQueryData([`campaign-${campaign.id}`], (old) => {
        return { ...old, post_image: imageObj.post_image };
      });
      setIsImageReady(true);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const finalizeTemplate = async (_template) => {
    setIsTemplateLoading(false);
    setCurrTemplate(_template);
  };

  useEffect(() => {
    if (isImageReady) {
      templateChangeCallback(currTemplate).then((t) => {
        store.loadJSON(t);
      });

      setIsImageReady(false);
    }
  }, [isImageReady]);

  useEffect(() => {
    if (campaign?.edit_state) {
      setCurrTemplate(campaign.edit_state);
      templateChangeCallback(campaign.edit_state).then((t) => {
        store.loadJSON(t);
      });
    } else {
      templateChangeCallback(currTemplate)
        .then((t) => {
          store.loadJSON(t);
        })
        .catch((error) => {
          const errorMessage = error.message || "An unexpected error occurred";
          NotificationService.notifyError(
            `Failed to generate captions: ${errorMessage}`
          );
        });
    }
  }, [campaign]);

  const updatedSections = DEFAULT_SECTIONS.filter((e) => {
    return !SKIP_DEFAULT_SECTIONS.includes(e.name);
  });
  updatedSections.push(ResizeSection);
  updatedSections.push(TemplatesSectionBuilder(templateChangeCallback));

  const cancelAction = () => {
    navigate(`/mkt/projects/${projectskuuid}/campaigns/${campaign.id}/assistant`);
  };
  const processImageMutation = useMutation({
    mutationFn: async ({ prompt, seed, steps = 100 }) => {
      try {
        setIsTemplateLoading(true);

        if (!campaign.edit_state) {
          const options = {
            regeneration_without_saving: true,
            edit_state: store.toJSON(),
          };
          await updateCampaignPost(campaign.id, 1, options);
        }

        setIsLoading(true);
        setIsImageReady(false);

        const imageObj = await processImage(
          campaign.id,
          prompt,
          seed,
          steps,
          false
        );

        queryClient.setQueryData([`campaign-${campaign.id}`], (old) => {
          return { ...old, post_image: imageObj.post_image };
        });

        setIsImageReady(true);
      } catch (error) {
        console.error("Error processing image:", error);
      } finally {
        setIsLoading(false);
        setIsTemplateLoading(false);
      }
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const saveAction = async () => {
    try {
      setIsTemplateLoading(true);
      const postPayload = {
        edit_state: store.toJSON(),
        post_edit_image: await store.toDataURL({
          pageId: currTemplate.pages[0].id,
          mimeType: "image/jpg",
        }),
        current_post_caption: campaign.caption,
      };

      const postUpdated = await updateCampaignPost(campaign.id, 1, postPayload);
      queryClient.setQueryData([`campaign-${campaign.id}`], (old) => {
        return { ...old, ...postUpdated };
      });
      NotificationService.notifySuccess("Campaign updated successfully");
      navigate(`/mkt/projects/${projectskuuid}/campaigns/${campaign.id}/assistant`);
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to update campaign post: ${errorMessage}`
      );
    } finally {
      setIsTemplateLoading(false);
    }
  };
  return (
    <section className="w-[calc(100vw-450px)] h-[calc(100vh-100px)]">
      <PolotnoContainer className="polotno-app-container">
        <SidePanelWrap>
          <SidePanel
            store={store}
            sections={updatedSections}
            defaultSection="custom-templates"
          />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar
            store={store}
            components={{
              ActionControls: ActionControlsBuilder(
                cancelAction,
                saveAction,
                () =>
                  processImageMutation.mutate({
                    prompt: campaign.image_prompt,
                    seed: random(0, 10000),
                  }),
                () => enhanceImageMutation.mutate(),
                isLoading
              ),
            }}
          />
          {(isTemplateLoading || !captions) && (
            <div
              style={{
                position: "absolute",
                top: "50px",
                zIndex: "20",
                width: "100%",
                height: "calc(100vh - 20px)",
              }}
              className="bg-gray-800 bg-opacity-50 backdrop-blur-sm flex justify-center items-center"
            >
              <Loading />
            </div>
          )}
          <Workspace
            store={store}
            components={{
              Tooltip,
              PageControls: () => null,
            }}
          />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </section>
  );
}

export default PostEditStep;
