import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams} from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import Image from "./image_generation/Image";
import Button from "./common/Button";
import { ReactComponent as EditIcon } from "../assets/edit_icon.svg";
import { ReactComponent as ScheduledStatusIcon } from "../assets/scheduled_status_icon.svg";
import PublishModal from "./Campaigns/CampaignPage/PublishModal";
import SourcesContainer from "./Campaigns/SourcesContainer";
import CaptionContainer from "./Campaigns/CaptionContainer";
import StatusType from "../data/enums/statusType";
import { FaCheck, FaTrashAlt } from "react-icons/fa";
import { ReactComponent as PublishedIcon } from '../assets/icons/published_icon.svg'
import SchedulePostModal from "./SchedulePostModal";
import * as NotificationService from "../utils/notificationService";
import { isEmpty } from "../utils/validations";
import { getDateAndTimeList, convertDateToISO, extractPkSk} from "../utils/common";
import {
  updateCampaign,
  schedulePost,
  publishPost,
  deleteCampaign,
  fetchCampaign,
  updatePost
} from "../services";

function PostPreviewSide({
  postData,
  className,
  references,
  postQuery,
  projectSocialAccounts,
  postTargetPlatforms,
}) {
  const navigate = useNavigate();
  const { campaignskuuid, projectskuuid } = useParams();
  const queryClient = useQueryClient();

  const postScheduledData = getDateAndTimeList(postData.schedule_time);
  const [scheduleTime, setScheduleTime] = useState(postData.schedule_time);
  const [postInfo, setPost] = useState(postData);
  const [showMoreText, setShowMoreText] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [postCaption, setPostCaption] = useState(postInfo.caption);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [schedulePostData, setSchedulePostData] = useState({
    start_date: postScheduledData[0],
    start_time: postScheduledData[1],
  });
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);

  const handleApprove = async () => {
    try{
      await updateCampaign(postInfo.id, {state: StatusType.DRAFT});
      postQuery.refetch();
    }catch(error){
      NotificationService.notifyError(
        `Failed to Approved the campaign: ${error.message}`
      );
    }
  }

  const handleCancel = async () => {
    try{
      await deleteCampaign(await fetchCampaign(postInfo.id));
      postQuery.refetch();
    }catch(error){
      NotificationService.notifyError(
        `Failed to delete the campaign: ${error.message}`
      );
    }
  }

  const handleSaveEditedCaption = async () => {
    await updatePost(postInfo.id, { caption: postCaption });
  };

  useEffect(() => {
    setPostCaption(postData.caption);
    setPost(postData);
    setSchedulePostData({
      start_date: postScheduledData[0],
      start_time: postScheduledData[1],
    });
    setScheduleTime(postData.schedule_time);

  }, [postData]);

  const handleSchedulePost = async () => {
    const payload = {
      post_id: postInfo.id,
      social_accounts_ids: selectedAccountIds,
      schedule_time: convertDateToISO(
        schedulePostData.start_date,
        schedulePostData.start_time
      ),
    };

    try {
      await schedulePost(payload)
      NotificationService.notifySuccess("Post scheduled successfully!");
      setScheduleTime(convertDateToISO(schedulePostData.start_date, schedulePostData.start_time));
      setPost((old) => ({ ...old, schedule_time: payload.schedule_time }));

    } catch (error) {
      cancelHandler();
      console.error("Failed to schedule post: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to schedule post: ${errorMessage}`
      );
    }
    finally {
      setOpenScheduleModal(false);
    }
  };

  const cancelHandler = () => {
    setOpenScheduleModal(false);
    setScheduleTime(postInfo.schedule_time)
    setSchedulePostData({
      start_date: postScheduledData[0],
      start_time: postScheduledData[1],
    });
  };

  const handlePublishPost = async () => {
    setIsPublishing(true);
    const publish = async () => {
      try {
        const { skuuid: postSkuuid } = extractPkSk(postInfo.id);
    
        const publishForAccount = async (accountId) => {
          const payload = {
            post_id: postInfo.id,
            include_references: showReferences,
            social_account_id: accountId,
          };
          return publishPost(payload);
        };
    
        const publishPromises = selectedAccountIds.map(publishForAccount);
        const results = await Promise.allSettled(publishPromises);
    
        const successfulResults = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => result.value);
        const errors = results
          .filter((result) => result.status === "rejected")
          .map((result) => result.reason);
    
        const targetPlatforms = successfulResults.flat();
    
        queryClient.setQueryData([`post-${postSkuuid}-platform-targets`], (oldData) => {
          if (!oldData) return targetPlatforms;
          const updatedList = oldData.map((item) => {
            const matchingItem = targetPlatforms.find((platform) => platform.id === item.id);
            return matchingItem ? { ...item, ...matchingItem } : item;
          });
          const newItems = targetPlatforms.filter(
            (platform) => !oldData.some((item) => item.id === platform.id)
          );
          return [...updatedList, ...newItems];
        });
    
        if (errors.length > 0) {
          NotificationService.notifyError(`Some posts failed to publish`);
        } else {
          NotificationService.notifySuccess("Post published successfully!");
        }
      } catch (error) {
        console.error("Failed to publish post: ", error);
        const errorMessage = error.message || "An unexpected error occurred";
        NotificationService.notifyError(`Failed to publish post: ${errorMessage}`);
      } finally {
        setOpenPublishModal(false);
        setIsPublishing(false);
      };
    };

    await publish();
  };

  const renderState = () => {
    switch (postInfo.state) {
      case "PUBLISHED":
        return (<><PublishedIcon /> Published</>);
      default:
        return (<><ScheduledStatusIcon /> Draft</>);
    }
  };

  const canEdit = useMemo(() => {
    if (isPublishing) return !isPublishing;
    return postInfo?.status !== StatusType.PUBLISHED;
  }, [postInfo.status, isPublishing]);

  const handleDateTimeChange = (e) => {
    const { id, value } = e.target;
    if (id === "campaign_start_date") {
      setSchedulePostData((prevState) => ({
        ...prevState,
        start_date: value,
      }));
    } else if (id === "campaign_start_time") {
      setSchedulePostData((prevState) => ({
        ...prevState,
        start_time: value,
      }));
    }
  };

  const onClickPublishButton = () => {
    setOpenPublishModal(true);
  }

  const handlePublishSchedule = () => {
    setOpenPublishModal(false);
    setOpenScheduleModal(true);
  };
  const handleScheduleCancel = () => {
    setOpenPublishModal(true);
    setOpenScheduleModal(false);
  };
  
  return (
    <div className={className + " flex flex-col justify-start"}>
      {postInfo.status === StatusType.IN_REVIEW && (
        <div className="flex justify-end gap-x-2">
          <button className="w-28 h-11 bg-[#22C55E] hover:bg-green-500 text-white flex items-center justify-center gap-1" onClick={handleApprove}>
            <FaCheck />
            Approve
          </button>
          <button className="w-28 h-11 bg-[#EF4444] hover:bg-red-600 text-white flex items-center justify-center gap-1" onClick={handleCancel}>
            <FaTrashAlt />
            Delete
          </button>
        </div>
      )}
      <div className="relative my-2">
        <div className="px-4 pt-2 sm:px-1 flex flex-wrap flex-row justify-between gap-1 items-center">
          {postInfo.caption && postInfo.image && canEdit ? (
            <div className="lg:basis-[43%] xl:basis-[30%] lg:ml-3 flex items-center justify-center ">
              <button
                onClick={() => {
                  navigate(`/mkt/projects/${projectskuuid}/campaigns/${campaignskuuid}/edit`);
                  window.location.reload();
                }}
                className="flex items-center font-medium text-main-blue hover:text-dark-blue focus:outline-none"
                aria-label="Edit design studio"
              >
                <EditIcon className="text-light-gray hover:text-dark-gray transition-colors duration-200" />
                <span className="ml-2">Design Studio</span>
              </button>
            </div>
          ) : (
            <div className="w-11 h-11 rounded-3xl bg-mid-light-gray lg:basis-[43%] xl:basis-[10%]"></div>
          )}

          {postInfo.caption && postInfo.image ? (
            <div
              className="relative"
              style={{ color: "#1DA1F2", borderColor: "#1DA1F2" }}
            >
              <Button
                onClick={onClickPublishButton}
                className="w-40"
                disabled={!canEdit}
              >
                Publish
              </Button>
            </div>
          ) : (
            <div className="w-24 h-11 rounded-3xl bg-mid-light-gray"></div>
          )}
        </div>
      </div>
      {postInfo.image_prompt ? (
        <Image key={postInfo?.id} post={postInfo} disabled={canEdit} />
      ) : (
        <div className="h-[521px] bg-mid-light-gray"></div>
      )}

      <CaptionContainer
        postCaption={postCaption}
        originalCaption={postInfo.caption}
        isEditing={isEditing}
        setPostCaption={setPostCaption}
        setShowMoreText={setShowMoreText}
        showMoreText={showMoreText}
        setIsEditing={setIsEditing}
        renderState={renderState}
        saveEdition={handleSaveEditedCaption}
      />
      {references.length > 0 && !isEmpty(references[0]) && (
        <SourcesContainer
          references={references}
          showReferences={showReferences}
          setShowReferences={setShowReferences}
        />
      )}

      <PublishModal
        openModal={openPublishModal}
        setOpenModal={setOpenPublishModal}
        projectSocialAccounts={projectSocialAccounts}
        postTargetPlatforms={postTargetPlatforms}
        handlePublish={handlePublishPost}
        handleSchedule={handlePublishSchedule}
        selectedAccountIds={selectedAccountIds}
        setSelectedAccountIds={setSelectedAccountIds}
      />

      <SchedulePostModal
        openModal={openScheduleModal}
        setOpenModal={setOpenScheduleModal}
        postInfo={postInfo}
        scheduleTime={scheduleTime}
        schedulePostData={schedulePostData}
        handleDateTimeChange={handleDateTimeChange}
        schedulePostHandler={handleSchedulePost}
        handleCancel={handleScheduleCancel}
      />
    </div>
  );
}

export default PostPreviewSide;
