import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";
import { extractPkSk } from "../utils/common";

export const createCampaign = async (payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createCampaign:", error);
    throw error;
  }
};

export const fetchCampaign = async (projectskuuid, campaignSkuuid) => {
  const session = await fetchAuthSession();
  const response = await fetch(`${API_SERVER}/projects/${projectskuuid}/campaigns/${campaignSkuuid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.tokens.idToken.toString()}`,
    },
  });
  const responseData = await response.json();
  if (!response.ok)
    throw new Error(responseData.error || "Network response was not ok");
  return responseData;
};

export const fetchCampaigns = async () => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaigns`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(responseData.error || "Network response was not ok");
    }

    return responseData;
  } catch (error) {
    console.error("Error Fetching campaigns:", error);
    throw error;
  }
};

export const updateCampaign = async (campaignId, data) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaigns/${campaignId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to updateCampaign:", error);
    throw error;
  }
};

export const deleteCampaign = async (campaignid) => {
  try {
    const { pkuuid, skuuid } = extractPkSk(campaignid);
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/projects/${pkuuid}/campaigns/${skuuid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to deleteCampaign:", error);
    throw error;
  }
};

export const publishCampaignPost = async (
  campaignId,
  postId,
  showReferences
) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignId}/posts/${postId}/publish`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({ include_references: showReferences }),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to publishCampaignPost:", error);
    throw error;
  }
};

export const updateCampaignPost = async (campaignId, postId, post) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignId}/posts/${postId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(post),
      }
    );
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to updateCampaignPost:", error);
    throw new Error(error);
  }
};

export const fetchMultiCampaign = async (multiCampaignId) => {
  const session = await fetchAuthSession();
  const response = await fetch(
    `${API_SERVER}/multicampaigns/${multiCampaignId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    }
  );
  const responseData = await response.json();
  if (!response.ok)
    throw new Error(responseData.error || "Network response was not ok");
  return responseData;
};

export const createMultiCampaign = async (campaign) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/multicampaigns`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(campaign),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createMultiCampaign:", error);
    throw error;
  }
};

export const processImage = async (
  campaignSkuuid,
  postSkuuid,
  prompt,
) => {
  try {
    const session = await fetchAuthSession();
    const baseUrl = `${API_SERVER}/campaigns/${campaignSkuuid}/posts/${postSkuuid}/image`;
    const response = await fetch(baseUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify({
        prompt: prompt,
      }),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to processImage:", error);
    throw error;
  }
};

export const enhanceImage = async (campaignId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignId}/image/enhance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify({}),
      }
    );
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to enhanceImage:", error);
    throw error;
  }
};

export const updateCampaignMetrics = async (campaignId, metrics) => {
  try {
    const session = await fetchAuthSession();
    const payloadMetrics = {
      metrics: {
        impression_count: metrics.views,
        like_count: metrics.reactions,
        reply_count: metrics.comments,
      },
    };
    const response = await fetch(`${API_SERVER}/metrics/${campaignId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payloadMetrics),
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to updateCampaignMetrics:", error);
    throw error;
  }
}

export const fetchCampaignPosts = async (campaignSkuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(`${API_SERVER}/campaign/${campaignSkuuid}/posts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
    });
    const responseData = await response.json();
    if (!response.ok)
      throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createPost:", error);
    throw error;
  }
};

export const fetchProjectCampaigns = async (projectskuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/projects/${projectskuuid}/campaigns`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to fetchProjectCampaigns:", error);
    throw error;
  }
};