import React from "react";
import { CREATE_KB_MAIN_TITLE } from "./../../data/textConstants";

const TitleWithProgres = ({imgSrc, altText }) => {
    return (
      <div className="flex flex-col items-center self-center w-full text-2xl font-medium leading-8 text-black max-w-[860px] max-md:max-w-full">
        <div className="gap-2.5 text-center self-stretch py-3 w-full bg-white max-md:max-w-full">
          {CREATE_KB_MAIN_TITLE}
        </div>
        <div className="flex mt-5 w-full max-md:max-w-full">
          <img src={imgSrc} alt={altText} />
        </div>
      </div>
    );
  };

export default TitleWithProgres;