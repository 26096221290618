import React from "react";
import AddDataFilterButton from "./AddDataFilterButton";

const DataFiltersTitle = ({ onClickAddDataFilter }) => {
    return (
        <div className="flex items-center justify-between">
            <h3 className="text-[20px] font-normal leading-[22px] tracking-[0.25px] text-left text-black font-gilroy_semibold">
                Data Filters
            </h3>
            {onClickAddDataFilter && <AddDataFilterButton onClick={onClickAddDataFilter} />}
        </div>
    );
};

export default DataFiltersTitle;
