import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";
import { delay } from "../utils/common";

export const fetchMessagesByThreadId = async (campaignId, activeThreadId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignId}/assistant/thread/${activeThreadId}/messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to fetchMessagesByThreadId:", error);
    throw error;
  }
};

export const sendMessage = async (campaignSkuuid, postSkuuid, payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/campaigns/${campaignSkuuid}/posts/${postSkuuid}/user/messages`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to sendMessage:", error);
    throw error;
  }
};

export const fetchMessageByRunId = async (campaignSkuuid, postSkuuid, payload) => {
  const session = await fetchAuthSession();
  const response = await fetch(
    `${API_SERVER}/campaigns/${campaignSkuuid}/posts/${postSkuuid}/assistant/messages`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.tokens.idToken.toString()}`,
      },
      body: JSON.stringify(payload),
    }
  );
  if (!response.ok) {
    const responseData = await response.json();
    throw new Error(responseData.error || "Network response was not ok");
  }

  if (response.status === 202) {
    await delay(1000);
    return fetchMessageByRunId(campaignSkuuid, postSkuuid, payload);
  }

  const responseData = await response.json();
  return responseData.messages;
};

export const deleteMessage = async (threadId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/assistant/thread/${threadId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to deleteMessage:", error);
    throw error;
  }
};
