import { useContext, useState } from "react"

import { AuthContext } from "../../AuthProvider"

import { ReactComponent as ArrowDropDownIcon } from '../../assets/arrow_drop_down.svg'

function UserProfile() {
  const [showDropDown, setShowDropDown] = useState(false)

  const auth = useContext(AuthContext)

  const togglerDropDown = () => {
    setShowDropDown(!showDropDown)
  }

  return (
    <div>
      <button type="button" onClick={togglerDropDown} className="self-stretch p-2 bg-white rounded-[5px] shadow justify-start items-center gap-[15px] inline-flex">
        <div className="w-9 h-9 p-2.5 bg-main-blue rounded-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="text-center text-white text-base font-normal leading-normal tracking-wide">
            {auth.user.given_name[0]}{auth.user.family_name[0]}
          </div>
        </div>
        <div className="grow shrink basis-0 text-base font-normal leading-normal tracking-wide">{auth.user.given_name} {auth.user.family_name}</div>
        <ArrowDropDownIcon/>
      </button>
      { showDropDown &&
          <div className="z-10 absolute bottom-20 w-56 bg-white divide-y divide-gray-100 rounded-[5px] shadow">
            <ul className="py-2 text-base font-normal" aria-labelledby="dropdownTopButton">
              <li>
                <a href onClick={auth.signout} className="block px-4 py-2 text-base font-normal">Sign out</a>
              </li>
            </ul>
          </div>
      }
    </div>
  )
}

export default UserProfile
