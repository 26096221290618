import React from "react";
import { useNavigate } from "react-router-dom";

import { extractPkSk } from "../../utils/common";
import LocalStorageKeys from "../../data/enums/localStorageKeys";

const ProjectCard = ({
    project,
}) => {
    const navigate = useNavigate();

    return (
        <div
            key={project.id}
            className="relative flex flex-col justify-center bg-white rounded min-h-[220px] shadow-lg w-full p-4 cursor-pointer"
            onClick={() => {
                const { skuuid } = extractPkSk(project.id);
                localStorage.setItem(LocalStorageKeys.PROJECT_ID_KEY, project.id);
                navigate(`/mkt/projects/${skuuid}/campaigns`);
            }}
        >
            <div
                className="text-lg text-center font-bold text-[#1DA1F2] overflow-hidden truncate"
                title={project.title}
            >
                {project.title}
            </div>
        </div>
    );
};

export default ProjectCard;
