import React, { useState } from "react";

const CampaignTypeFilter = ({ filterType, handleFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Filter"
        className="p-2 border border-gray-300 rounded-md mr-4"
        value={filterType}
        readOnly
        onClick={() => setIsOpen(!isOpen)}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 absolute right-5 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer"
        viewBox="0 0 24 24"
        fill="currentColor"
        onClick={() => setIsOpen(!isOpen)}
      >
        <path
          fillRule="evenodd"
          d="M 6.293 7.293 a 1 1 0 0 1 1.414 0 L 10 9.586 l 2.293 -2.293 a 1 1 0 1 1 1.414 1.414 l -3 3 a 1 1 0 0 1 -1.414 0 l -3 -3 a 1 1 0 0 1 0 -1.414 z z"
          clipRule="evenodd"
        />
      </svg>
      {isOpen && (
        <div className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
          <ul className="py-1">
            <li
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                handleFilterChange("Single");
                setIsOpen(false);
              }}
            >
              Single
            </li>
            <li
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                handleFilterChange("Multiple");
                setIsOpen(false);
              }}
            >
              Multiple
            </li>
            <li
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                handleFilterChange("All");
                setIsOpen(false);
              }}
            >
              All
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default CampaignTypeFilter;
