import React, { useState, useRef, useEffect } from "react";

import * as NotificationService from "../../utils/notificationService";
import { updatePost } from "../../services/PostServices";

const EditableSpan = ({ initialContent, setIsInput, postId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(initialContent);
  const [auxContent, setAuxContent] = useState(initialContent);
  const ref = useRef(null);

  const handleDoubleClick = () => {
    setIsEditing(true);
    setIsInput(true);
  };

  const handleChange = (e) => {
    setContent(e.target.value);
  };

  const saveChanges = async () => {
    try {
      if (content.trim() === "") throw new Error("Title cannot be empty");
      
      const payload = {
        title: content
      }
      const updatedPost = await updatePost(postId, payload);
      const currentPostTitle = updatedPost.title;
      NotificationService.notifySuccess(
        "Post title updated successfully"
      );
      return currentPostTitle;
    } catch (error) {
      console.error("Failed to update post title: ", error);
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to update post title: ${errorMessage}`
      );
      setContent(initialContent);
    } finally {
      setIsInput(false);
      setIsEditing(false);
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const postTitleUpdated = await saveChanges();
      setAuxContent(postTitleUpdated);
    }
    if (e.key === "Escape") {
      e.preventDefault();
      setContent(auxContent);
      setIsInput(false);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = async (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const postTitleUpdated = await saveChanges();
        setAuxContent(postTitleUpdated);
        setIsInput(false);
        setIsEditing(false);
      }
    };

    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };


  }, [isEditing, saveChanges]);

  return (
    <div>
      {!isEditing ? (
        <span 
          onDoubleClick={handleDoubleClick}
          className="flex text-sm cursor-text"
        >
          {content}
        </span>
        ) : (
        <input 
          className="border-0 focus:outline-none bg-transparent focus:ring-0 p-0 m-0 flex text-sm cursor-text h-5 w-1/2"
          type="text"
          autoFocus 
          ref={ref} 
          value={content} 
          onKeyDown={handleKeyDown} 
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default EditableSpan;