import React from 'react'
import LandingHeader from './LandingHeader'
import LandingHero from './LandingHero'
import BenefitsSection from './BenefitsSection'
import TemplatesSection from './TemplatesSection'
import HowItWorksSection from './HowItWorksSection'
import KeyFeaturesSection from './KeyFeaturesSection'
import Footer from './Footer'

export default function LandingPageV2() {
  return (
    <div id="home">
      <LandingHeader />
      <div className='px-8 py-1'>
        <LandingHero />
        <div className='py-[24px]'></div>
        <BenefitsSection />
        <div className='py-[24px]'></div>
        <TemplatesSection />
        <div className='py-[24px]'></div>
        <HowItWorksSection />
        <div className='py-[24px]'></div>
        <KeyFeaturesSection />
      </div>
      <Footer />
    </div>
  )
}
