import React from "react";
import { READABLE_SITE_NAME, SITE_EMAIL } from "../../config/constants";
import campaignsPlanetLogo from "../../assets/logos/campaigns-planet-logo.svg";

const Footer = () => {
  return (
    <div className="py-12 pr-20 pl-10 bg-black max-md:px-5">
      <div className="flex flex-col w-[32%] max-md:ml-0 max-md:w-full">
        <div className="flex gap-5 justify-between items-start max-md:mt-10">
          <div className="flex flex-col flex-1 self-start text-white">
            <div className="flex gap-3 text-2xl font-medium leading-5 whitespace-nowrap">
              <img
                src={campaignsPlanetLogo}
                alt="Campaigns Planet Logo"
                className="h-8"
              />
              <span className="text-lg font-bold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-orange-500 md:block place-self-center">
                {READABLE_SITE_NAME.toUpperCase()}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="grow mt-10 max-md:mt-10 max-md:max-w-full">
        <div className="text-xl font-bold tracking-normal text-white">
          Get in touch
        </div>
        <div className="mt-7 text-base tracking-normal leading-7 text-white">
          <span className="leading-5 font-[450]">
            San Jose, United States
          </span>
          <br />
          <span className="font-[450]">{SITE_EMAIL}</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
