import React, { useState } from "react";
import { ReactComponent as ClipboardIcon } from "../../assets/clipboard.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checked_icon.svg";
import { copyToClipboardHandler } from "../../utils/common";

const SourcesContainer = ({
  references,
}) => {

  const [referencesCopied, setReferencesCopied] = useState(null);

  const copyReferencesHandler = () => {
    const referencesToCopy = references
      .map((reference) => {
        return `${reference.author ?? reference.title} - ${
          reference.url
        }`;
      })
      .join("\n");
    copyToClipboardHandler(referencesToCopy, setReferencesCopied);
  };

  return (
    <div className="w-full border mx-auto">
      <div className="border-b px-4 py-2 flex items-center justify-between">
        <h3 className="font-semibold text-lg">Sources</h3>

        <div>
          {referencesCopied ? (
            <button className="p-2 border rounded transition-colors duration-200 bg-green-100 hover:bg-green-200">
              <CheckedIcon className="h-4 w-4 text-green-500" />
            </button>
          ) : (
            <button
              onClick={copyReferencesHandler}
              className="p-2 border rounded transition-colors duration-200 hover:bg-gray-200"
              aria-label="Copy to Clipboard"
              title="Copy to Clipboard"
            >
              <ClipboardIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>

      <div className="p-4">
        <ul className="list-disc pl-4 space-y-2">
          {references.map((reference, index) => (
            <li key={index} className="text-sm break-words">
              <a
                href={reference.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-main-blue underline break-all hover:text-blue-600 transition-colors duration-200"
              >
                {reference.url}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SourcesContainer;
