import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import * as NotificationService from "../../utils/notificationService";
import {
    fetchCampaign,
    fetchCampaignPosts,
    fetchKnowledgeBases,
    fetchSystemPromptTemplates,
    fetchPromptTemplates,
    fetchPost,
    fetchApps,
    fetchPostTargetPlatforms,
    deletePost,
} from "../../services";
import CampaignHeader from "./CampaignPage/CampaignHeader";
import CampaignPostsDisplayer from "./CampaignPage/CampaignPostsDisplayer";
import PostConversation from "./CampaignPage/PostConversation";
import CreatePostModal from "../posts/CreatePostModal";
import PostPreviewSide from "../PostPreviewSide";
import EmptyStateScreen from "./EmptyStateScreen";
import Button from "./../common/Button";
import { extractPkSk } from "../../utils/common";

export default function CampaignPage() {
    const queryClient = useQueryClient();
    const { campaignskuuid, projectskuuid } = useParams();
    const [selectedPostSkuuid, setSelectedPostSkuuid] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [postReferences, setPostReferences] = useState([]);

    const campaignQuery = useQuery({
        queryKey: [`campaign-${campaignskuuid}`],
        queryFn: () => fetchCampaign(projectskuuid, campaignskuuid),
        staleTime: 10 * 1000,
        onError: (error) => {
            NotificationService.notifyError(`Failed to fetch campaign: ${error.message}`);
        },
    });

    const postsQuery = useQuery({
        queryKey: [`campaign-${campaignskuuid}-posts`],
        queryFn: () => fetchCampaignPosts(campaignskuuid),
        staleTime: 10 * 1000,
        onError: (error) => {
            NotificationService.notifyError(`Failed to fetch posts: ${error.message}`);
        },
    });

    const postQuery = useQuery({
        queryKey: [`post-${selectedPostSkuuid ?? "no-ready"}`],
        queryFn: () => (selectedPostSkuuid ? fetchPost(campaignskuuid, selectedPostSkuuid) : {}),
        staleTime: 10 * 1000,
        enabled: !!selectedPostSkuuid,
        onError: (error) => {
            NotificationService.notifyError(`Failed to fetch post: ${error.message}`);
        },
    });

    const knowledgeBasesQuery = useQuery({
        queryKey: ["knowledgeBases"],
        queryFn: fetchKnowledgeBases,
        staleTime: 10 * 1000,
    });

    const systemPromptTemplatesQuery = useQuery({
        queryKey: ["system-prompt-templates"],
        queryFn: fetchSystemPromptTemplates,
        staleTime: 10 * 1000,
    });

    const userPromptTemplatesQuery = useQuery({
        queryKey: [`user-prompt-templates`],
        queryFn: () => fetchPromptTemplates(),
        staleTime: 10 * 1000,
    });

    const userSocialAccountsQuery = useQuery({
        queryKey: [`user-social-accounts`],
        queryFn: () => fetchApps(projectskuuid),
        staleTime: 10 * 1000,
    });

    const postTargetPlatformsQuery = useQuery({
        queryKey: [`post-${selectedPostSkuuid ?? "post_id_no_ready"}-platform-targets`],
        queryFn: () => (selectedPostSkuuid ? fetchPostTargetPlatforms(selectedPostSkuuid) : {}),
        staleTime: 10 * 1000,
        enabled: !!selectedPostSkuuid,
        onError: (error) => {
            NotificationService.notifyError(`Failed to fetch post target platform: ${error.message}`);
        },
    });

    useEffect(() => {
        if (postsQuery.data?.length > 0 && !selectedPostSkuuid) {
            const defaultPost = postsQuery.data[0];
            const { skuuid: postSkuuid } = extractPkSk(defaultPost.id);
            setSelectedPostSkuuid(postSkuuid);
        }
    }, [postsQuery.data, selectedPostSkuuid]);

    if (campaignQuery.isLoading || postsQuery.isLoading) {
        return <div>Loading...</div>;
    }

    const messagesUpdatedCallback = (messages) => {
        const allReferences = extractReferences(messages ?? []);
        setPostReferences(allReferences);
    };

    const extractReferences = (data) => {
        const references = new Set();

        data.forEach((element) => {
            if (element.reference && Array.isArray(element.reference)) {
                element.reference.forEach((ref) => {
                    references.add(JSON.stringify(ref));
                });
            }
        });

        return Array.from(references).map((ref) => JSON.parse(ref));
    };

    const deletePostHandler = async (post) => {
        try {
            const { pkuuid: postDeletedPkuuid, skuuid: postDeletedSkuuid } = extractPkSk(post.id);
            await deletePost(post.id);
            NotificationService.notifySuccess("Post deleted successfully");

            queryClient.setQueryData(
                [`campaign-${postDeletedPkuuid}-posts`],
                (oldData) => {
                    const updatedPosts = oldData?.filter((p) => p.id !== post.id) || [];

                    if (postDeletedSkuuid === selectedPostSkuuid && updatedPosts.length > 0) {
                        const newSelectedPost = updatedPosts[0];
                        const { skuuid: newPostSkuuid } = extractPkSk(newSelectedPost.id);
                        setSelectedPostSkuuid(newPostSkuuid);
                    } else if (updatedPosts.length === 0) {
                        setSelectedPostSkuuid(null);
                    }

                    return updatedPosts;
                }
            );

            queryClient.invalidateQueries({
                predicate: (query) =>
                    query.queryKey[0] === `post-${postDeletedSkuuid}` ||
                    query.queryKey[0] === `post-${postDeletedSkuuid}-messages`,
            });

        } catch (error) {
            const errorMessage = error.message || "An unexpected error occurred";
            NotificationService.notifyError(
                `Failed to delete post: ${errorMessage}`
            );
        }
    };


    return (
        <div className="flex flex-row w-full">
            <div className="h-full w-full flex flex-col">
                {campaignQuery.data && <CampaignHeader campaign={campaignQuery.data} />}

                {postsQuery.data?.length === 0 && (
                    <EmptyStateScreen setOpenModal={setOpenModal} />
                )}

                {postsQuery.data?.length > 0 && (
                    <div className="hidden max-md:100 md:flex">
                        <div className="flex flex-col h-full p-4 md:w-[20%]">
                            <Button outline onClick={() => setOpenModal(true)}>+ New Post</Button>
                            <div className="hidden md:block">
                                <CampaignPostsDisplayer
                                    posts={postsQuery.data}
                                    selectedPost={postQuery.data}
                                    setSelectedPostSkuuid={setSelectedPostSkuuid}
                                    deletePostHandler={deletePostHandler}
                                />
                            </div>
                        </div>
                        {selectedPostSkuuid && postQuery.data && (
                            <>
                                <div className="flex flex-col h-full p-4 md:w-[50%]">
                                    <PostConversation
                                        post={postQuery.data}
                                        systemPromptTemplates={systemPromptTemplatesQuery?.data?.data}
                                        projectPromptTemplates={userPromptTemplatesQuery?.data?.data}
                                        messagesUpdatedCallback={messagesUpdatedCallback}
                                    />
                                </div>

                                <div className="flex flex-col h-full p-4 md:w-[30%]">
                                    <PostPreviewSide
                                        postData={postQuery.data}
                                        className="rounded-3xl border border-mid-light-gray shadow-sm basis-[548px] overflow-y-auto min-h-[800px] no-scrollbar"
                                        references={postReferences}
                                        projectSocialAccounts={userSocialAccountsQuery?.data ?? []}
                                        postTargetPlatforms={postTargetPlatformsQuery?.data ?? []}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}

                <div className="md:hidden flex flex-row bg-transparent text-2xl align-middle items-center p-10 max-md:h-[calc(100vh-70px)] text-center">
                    This functionality was designed mainly for tablets and desktops
                </div>

                <CreatePostModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    knowledgeBases={knowledgeBasesQuery.data}
                    campaignId={`PJ#${projectskuuid}&CP#${campaignskuuid}`}
                    refetch={knowledgeBasesQuery.refetch}
                />

            </div>
        </div>
    );
}